import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import { useStyles } from "./playbookStyles.js";
import Grid from '@material-ui/core/Grid';
// import Paper from '@material-ui/core/Paper';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import ListItemText from '@material-ui/core/ListItemText';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
    getPlayBooksContent,
    getPlayBooksRegionFilters,
    updateRegionFilters,
    resetPlaybooksRegionFilters,
} from '../../redux/actions/index';
import DefaultReport from '../reports/defaultReport';
// import Report from '../reports/report';
import Button from '@material-ui/core/Button';
import utils from '../../utils/index';
import NoData from '../../assets/icons/NoData.png';

const PowerBIReport = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    // let history = useHistory();
    const { activeIndex, open } = props;

    const playbooksRegionFilter = useSelector((state) => state.playbooksRegionFilter);
    const playbooksSelectedRegionFilter = useSelector((state) => state.playbooksSelectedRegionFilter);
    const playBooksContent = useSelector((state) => state.playbookContent);
    const activeReport = useSelector((state) => state.activePBIReport);
    const playbookContentPages = useSelector((state) => state.playbookContentPages);
    const playbookRegionValidate = useSelector((state) => state.regionValidate);
    const activeReportSection = useSelector((state) => state.activeReportSection);

    const bookmarkView = useSelector((state) => state.bookmarkView);
    const ShareLinkData = useSelector((state) => state.fetchShareLinkDetails);
    const selectedSubRegion = useSelector((state) => state.updatedSubRegion);
    const errorMessage = useSelector((state) => state.getErrorMessages);

    const [subRegion, setSubRegion] = useState('');
    const [noReports, setNoReport] = React.useState(null);
    const { /*activeMsalToken,*/ activePage, activeRegion } = useSelector((state) => state.activeDynamicData);

    useEffect(() => {
        const noReport = errorMessage?.data?.find(item => item.errorType === "No Reports Available")
        setNoReport(noReport?.errorMessage)
    }, [errorMessage]);

    useEffect(() => {
        if (!!selectedSubRegion && Object.keys(selectedSubRegion).length > 0) {
            let subRegion = selectedSubRegion.subRegion;
            setSubRegion(subRegion);
        }
    }, [selectedSubRegion]);

    useEffect(() => {
        const data = sessionStorage.getItem('oktaRes');
        const sessionData = !!data ? JSON.parse(data) : null;
 
        if (activeRegion && subRegion !== '') {
            const params = {
                pageType: 'Playbooks',
                region: activeRegion,
                subRegion: subRegion,
            }
            console.log('updatedPlaybookData', activeRegion, subRegion, params,)
            let validate = subRegion ? playbookRegionValidate['payload'][activeRegion][subRegion] : playbookRegionValidate['payload'][activeRegion]
            if (JSON.stringify(params) === JSON.stringify(validate)) {
                dispatch(getPlayBooksContent(params));
                if (activePage && activePage['globalFilter']) {
                    dispatch(getPlayBooksRegionFilters({
                        ...params,
                        userId: sessionData && sessionData.email
                    }));
                }
            }
        }
    }, [dispatch, activeRegion, activePage, subRegion, playbookRegionValidate]);

    console.log('updatedPlaybookData', activeRegion, subRegion, activePage, activeReport , activeReport.isReportActive, activeReport.grouped );

    useEffect(() => {
        if (activePage && activePage['globalFilter'] && playbooksRegionFilter && !playbooksRegionFilter.loading && !!activeRegion) {
            if (Object.keys(playbooksRegionFilter.data).length > 0) {
                let dataSet = {};
                let currentDataSet = playbooksRegionFilter.data;
                let persistDataSet;
                const data = localStorage.getItem('reportFilter');
                const bookmarkFilter = !!bookmarkView && bookmarkView.selectedFilters;
                let ShareLink = ShareLinkData && ShareLinkData.data[0];
                const shareLinkFilter = !!ShareLink && ShareLink.selectedFilters;
                const appliedFilter = !!bookmarkFilter ? JSON.parse(bookmarkFilter) : !!shareLinkFilter ? JSON.parse(shareLinkFilter) : !!data ? JSON.parse(data) : null;
                playbooksRegionFilter.schema[activeRegion].metadata.map((item) => {
                    let tempKey = item.key;
                    let activeItem = '';
                    let optionList = '';


                    if (currentDataSet && currentDataSet[tempKey] && currentDataSet[tempKey] !== null && Object.keys(currentDataSet[tempKey]).length > 0) {
                        if (Array.isArray(currentDataSet[tempKey])) {
                            if (item['previous'] !== undefined) {
                                optionList = [];
                                dataSet[item.previous]['activeItem'].map((optKey) => {
                                    if (persistDataSet[item.previous] && persistDataSet[item.previous][optKey] && persistDataSet[item.previous][optKey]['domestic_export_interco']) {
                                        persistDataSet[item.previous][optKey]['domestic_export_interco'].map((optVal) => {
                                            if (optionList.indexOf(optVal) == -1) {
                                                optionList.push(optVal);
                                            }
                                        })
                                    }
                                })
                            }
                            else {
                                optionList = currentDataSet[tempKey];
                            }
                        }
                        else {
                            optionList = Object.keys(currentDataSet[tempKey]);
                        }


                        if (!!appliedFilter && appliedFilter.dataSet && appliedFilter.dataSet.hasOwnProperty(tempKey) && appliedFilter.dataSet[tempKey].hasOwnProperty('activeItem')) {
                            if (item.type !== 'multiSelect' && !!optionList && optionList.indexOf(appliedFilter.dataSet[tempKey]['activeItem']) !== -1) {
                                activeItem = appliedFilter.dataSet[tempKey]['activeItem']
                            }
                            else if (item.type === 'multiSelect' && !!appliedFilter && appliedFilter.dataSet.hasOwnProperty(tempKey) && appliedFilter.dataSet[tempKey].hasOwnProperty('activeItem')) {
                                if (!!optionList && Array.isArray(appliedFilter.dataSet[tempKey]['activeItem']) && appliedFilter.dataSet[tempKey]['activeItem'].length > 0) {
                                    let selectedItem = appliedFilter.dataSet[tempKey]['activeItem'];
                                    let tempActiveItems = [];
                                    appliedFilter.dataSet[tempKey]['activeItem'].map((item) => {
                                        if (optionList.indexOf(appliedFilter.dataSet[tempKey]['activeItem']) !== -1) {
                                            tempActiveItems.psuh(appliedFilter.dataSet[tempKey]['activeItem'])
                                        }
                                    })
                                    if (tempActiveItems.length > 0) {
                                        activeItem = tempActiveItems;
                                    }
                                    else {
                                        activeItem = selectedItem;
                                    }
                                }
                                else {
                                    activeItem = optionList;
                                }
                            }
                            else {
                                activeItem = optionList[0]
                            }
                        }
                        else {
                            activeItem = item.type === 'multiSelect' ? optionList : optionList[0]
                        }

                        if (Array.isArray(activeItem)) {
                            persistDataSet = currentDataSet;
                            currentDataSet = currentDataSet[tempKey][activeItem[0]]
                        }
                        else {
                            currentDataSet = currentDataSet[tempKey][activeItem]
                        }
                    }
                    if (!!tempKey) {
                        dataSet[tempKey] = {
                            activeItem: activeItem,
                            optionList: optionList,
                            nextItemKey: item.next,
                        }
                    }
                    dispatch(updateRegionFilters(dataSet));
                    const data = sessionStorage.getItem('oktaRes');
                    const sessionData = !!data ? JSON.parse(data) : null;
                    localStorage.setItem('reportFilter', JSON.stringify({ user: sessionData && sessionData.email, region: activeRegion, dataSet: dataSet }));
                    return dataSet;
                })
            }
        }
    }, [dispatch, playbooksRegionFilter, bookmarkView, activeRegion, ShareLinkData, activePage]); // history.location.state,

    const trackingData = async (trackingFilter) => {
        let trackingObject = {
            region: activeRegion,
            actionType: 'Click',
            pageName: activePage['pageName'],
            reportName: activeReport.viewName,
            globalFilters: JSON.stringify(trackingFilter),
            reportLink: activeReport['htmlTemp']['reportLink'],
            groupId: activeReport['htmlTemp']['groupId'],
            reportId: activeReport['htmlTemp']['reportId'],
            subRegion: subRegion,
            reportSection: activeReportSection && activeReportSection.activeSubMenu,
            reportSectionId: activeReportSection && activeReportSection.activeSubMenuName,
        };
        await utils.userTrackingInfo(trackingObject);
    }

    const handleChange = (eventSource, event, label, currentValue) => {
        let trackingFilter = {};
        let dataSet = {};
        let currentDataSet = playbooksRegionFilter.data;
        let persistDataSet;
        let resetDependentFilters = false;
        playbooksRegionFilter.schema[activeRegion].metadata.map((item, index) => {
            let tempKey = item.key;
            let activeItem = '';
            let optionList = '';
            if (!!currentDataSet && currentDataSet[tempKey] && currentDataSet[tempKey] !== null && Object.keys(currentDataSet[tempKey]).length > 0) {
                if (resetDependentFilters) {
                    if (Array.isArray(currentDataSet[tempKey])) {
                        if (item['previous'] !== undefined) {
                            optionList = [];
                            dataSet[item.previous]['activeItem'].map((optKey) => {
                                if (persistDataSet[item.previous] && persistDataSet[item.previous][optKey] && persistDataSet[item.previous][optKey]['domestic_export_interco']) {
                                    persistDataSet[item.previous][optKey]['domestic_export_interco'].map((optVal) => {
                                        if (optionList.indexOf(optVal) === -1) {
                                            optionList.push(optVal)
                                        }
                                    })
                                }
                            })
                        }
                        else {
                            optionList = currentDataSet[tempKey];
                        }
                    }
                    else {
                        optionList = Object.keys(currentDataSet[tempKey]);
                    }
                }
                else {
                    optionList = playbooksSelectedRegionFilter.dataSet[tempKey]['optionList'];
                }
                if (item.key === eventSource.key && !resetDependentFilters) {
                    activeItem = event.target.value;
                    resetDependentFilters = true;
                    if (item.type === 'multiSelect') {
                        if (activeItem[activeItem.length - 1] === "all") {
                            activeItem = (activeItem.length - 1) === optionList.length ? [] : optionList;
                        }
                    }
                }
                else if (item.key !== eventSource.key && !resetDependentFilters) {
                    activeItem = playbooksSelectedRegionFilter.dataSet[tempKey]['activeItem'];
                }
                else if (item.key !== eventSource.key && resetDependentFilters) {
                    if (item.type === 'multiSelect') {
                        activeItem = optionList;
                    }
                    else {
                        activeItem = optionList[0];
                    }
                }
                if (item.type === 'multiSelect' && Array.isArray(activeItem) && activeItem.length > 0) {
                    let defaultOption = optionList[0]
                    persistDataSet = currentDataSet;
                    currentDataSet = currentDataSet[tempKey][defaultOption]
                }
                else if (item.type === 'multiSelect' && Array.isArray(activeItem) && activeItem.length === 0) {
                    let defaultOption = optionList[0]
                    persistDataSet = currentDataSet;
                    currentDataSet = currentDataSet[tempKey][defaultOption]
                }
                else {
                    currentDataSet = currentDataSet[tempKey][activeItem]
                }
            }
            if (!!tempKey) {
                dataSet[tempKey] = {
                    activeItem: activeItem,
                    optionList: optionList,
                    nextItemKey: item.next,
                }
            }
            return dataSet;
        });
        dispatch(updateRegionFilters(dataSet));
        !!dataSet && Object.keys(dataSet).map((item) => trackingFilter[item] = dataSet[item]['activeItem']);
        trackingData(trackingFilter);
        const data = sessionStorage.getItem('oktaRes');
        const sessionData = !!data ? JSON.parse(data) : null;
        localStorage.setItem('reportFilter', JSON.stringify({ user: sessionData && sessionData.email, region: activeRegion, dataSet: dataSet }));
    };

    const renderOptions = (item, optionList, dropDownType, currentVal) => {
        if (dropDownType === 'multiSelect') {
            return Array.isArray(optionList) && optionList.map((option, index) => {
                return (<MenuItem value={option} className={classes.menuItemOption}>
                    <Checkbox checked={currentVal.indexOf(option) > -1} color={'primary'} />
                    <ListItemText primary={option} />
                </MenuItem>)
            })
        }
        else {
            return Array.isArray(optionList) && optionList.map((option, index) => {
                return (<MenuItem value={option}>{option}</MenuItem>)
            })
        }
    };

    const handleMultiSelect = (selected, optionList) => {
        if (Array.isArray(selected) && Array.isArray(optionList)) {
            if (selected.length > 1 && selected.length < optionList.length) {
                return ("Multiple Sections")
            }
            else if (selected.length === 1) {
                return (selected.join(', '))
            }
            else if (optionList.length === selected.length) {
                return ("All")
            }
        }
    };

    const resetGlobalFiltersValues = () => {
        localStorage.removeItem('reportFilter');
        dispatch(resetPlaybooksRegionFilters({}));
        const data = sessionStorage.getItem('oktaRes');
        const sessionData = !!data ? JSON.parse(data) : null;
        if (!!activeRegion && !!activePage && sessionData) {
            const regionFilter = {
                pageType: activePage['key'],
                region: activeRegion,
                subRegion: subRegion,
                userId: sessionData.email
            };
            dispatch(getPlayBooksRegionFilters(regionFilter));
        }
    };

    const renderResetGlobalFiltersBtn = () => {
        if (activePage && activePage['globalFilter']) {
            if (!!activeRegion && !playbooksRegionFilter.loading && playbooksRegionFilter['schema'][activeRegion]['metadata'].length > 0) {
                return (
                    <Button color={'primary'} variant="outlined" className={classes.resetFilter} onClick={(e) => { resetGlobalFiltersValues() }}>
                        Reset Filters
                    </Button>)
            }
            else {
                return (
                    <Button disabled color={'default'} variant="outlined" className={classes.resetFilter} >Reset Filters</Button>

                )
            }
        }
    };

    const GlobalfilterNote = () => {
        if (activePage && activePage['globalFilter']) {
            return (
                <Grid container item xs={12} style={{ color: 'red', fontSize: '14px', marginTop : 12}}>
                           Product filters here are common across all sources, hence they are Global in nature.
                </Grid>
            )
        }
    }

    const renderGlobalFiltersElement = () => {
        if (!!activeRegion && activePage && activePage['globalFilter'] && playbooksRegionFilter['schema'][activeRegion]['metadata'].length > 0) {
            return (playbooksRegionFilter['schema'][activeRegion]['metadata']).map((item, index) => {
                let currentVal = '';
                let optionList = [];
                if (playbooksSelectedRegionFilter && playbooksSelectedRegionFilter.dataSet && (Object.keys(playbooksSelectedRegionFilter.dataSet).length > 0) && playbooksSelectedRegionFilter.dataSet[item.key]) {
                    currentVal = playbooksSelectedRegionFilter.dataSet[item.key]['activeItem'];
                    optionList = playbooksSelectedRegionFilter.dataSet[item.key]['optionList'];

                }
                if (item.type === 'singleSelect') {
                    return (
                        <FormControl className={classes.formControl}>
                            <InputLabel id={`select_label_${item.label}`} className={classes.filterLabel}>{item.label}</InputLabel>
                            <Select
                                labelId={`select_label_${item.label}`}
                                id={`select_${item.label}`}
                                value={currentVal}
                                selectType
                                onChange={(e) => handleChange(item, e, item.label, currentVal)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            // className={classes.filterLabel}
                            >
                                {renderOptions(item, optionList, item.type, currentVal)}
                            </Select>
                        </FormControl>
                    );
                }
                else if (item.type === 'multiSelect') {
                    let domestic = {
                        minWidth: '190px'
                    }
                    return (
                        <FormControl className={classes.formControl} style={item.label === "DOMESTIC/EXPORT/INTERCO" ? domestic : {}}>
                            <InputLabel id={`select_label_${item.label}`} className={classes.filterLabel}>{item.label}</InputLabel>
                            <Select
                                labelId={`select_label_${item.label}`}
                                id={`select_${item.label}`}
                                value={Array.isArray(currentVal) ? currentVal : []}
                                renderValue={(selected) => handleMultiSelect(selected, optionList)}
                                multiple
                                defaultValue="All"
                                style={{ 'maxWidth': '220px', 'width': '100%', }}
                                onChange={(e) => handleChange(item, e, item.label, currentVal)}
                                MenuProps={{
                                    anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                    },
                                    transformOrigin: {
                                        vertical: "top",
                                        horizontal: "left"
                                    },
                                    getContentAnchorEl: null
                                }}
                            // className={classes.filterLabel}
                            >
                                <MenuItem value="all" className={classes.filterCheckbox}>
                                    <Checkbox
                                        checked={optionList.length > 0 && currentVal.length === optionList.length}
                                        indeterminate={currentVal.length > 0 && currentVal.length < optionList.length}
                                        color={'primary'}
                                    />
                                    <ListItemText primary="Select All" />
                                </MenuItem>
                                {renderOptions(item, optionList, item.type, currentVal)}
                            </Select>
                        </FormControl>
                    );
                }
            })
        }
    };



    const renderReports = () => {
        const { loading, responseDefault, error } = playBooksContent;
        let { grouped, msalEnabled } = activeReport;
        if (loading && responseDefault) {
            return (
                <div className={classes.position}>
                    <CircularProgress color="primary" />
                </div>
            )
        }
        else if (!loading && !responseDefault && !!error) {
            return (
                <div className={classes.serverDown}>
                    <span><img src={NoData} alt="serverDown" className={classes.imgFit} /></span>
                    <div className={classes.errorMessage} >{noReports}</div>
                </div>
            )
        }
        else if (grouped && msalEnabled && !!playbookContentPages.loading && !playbookContentPages.error && playbookContentPages.data.length === 0) {
            return (
                <div className={classes.position}>
                    <CircularProgress color="primary" />
                </div>
            )
        }
        else if (grouped && msalEnabled && !playbookContentPages.loading && !!playbookContentPages.error && playbookContentPages.data.length === 0) {
            return (
                <div className={classes.serverDown}>
                    <span><img src={NoData} alt="serverDown" className={classes.imgFit} /></span>
                    <div className={classes.errorMessage} >{noReports}</div>
                </div>
            )
        }
        else if (Object.keys(activeReport).length > 0) {
            return (
                <DefaultReport
                    reportSectionId={activeReportSection && activeReportSection.activeSubMenuName}
                    subRegion={subRegion}
                    activeIndex={activeIndex}
               />
            )
        }
    }

    return (
        <Grid container item xs={12} className={classes.flexContainer}>
            {
                activeReport.globalFilter && activeReport.activeReportPage ?
                    <Grid container item xs={2} className={classes.globalFilter} >
                        {renderGlobalFiltersElement()}
                        {renderResetGlobalFiltersBtn()}
                        {GlobalfilterNote()}
                    </Grid>
                    : null
            }
            <Grid container item xs={!activeReport.activeReportPage ? 12 : 10} className={!open && activeReport.activeReportPage ? classes.marginAuto : null} >
                {
                    renderReports()
                }
            </Grid>
        </Grid>
    );
}
export default PowerBIReport;