import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import { globalSearchItem } from "../../redux/actions/index";
import "../businessUnit/style.css";
import { useStyles } from "../businessUnit/style.js";
import useDebounce from "./useDebounce";
import utils from "../../utils";
 

const GlobalSearch = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  // let history = useHistory();

  const searchResult = useSelector((state) => state.globalSearch);

  const [searchTerm, setSearchItem] = useState("");

  const [searchSuggestion, setSearchSuggestion] = useState(false);

  const { activeBusinessUnit, activeRegion } = useSelector(
    (state) => state.activeDynamicData
  );

  async function fetchData() {
    if (searchTerm !== "") {
      let params = {
        region: activeRegion,
        searchQuery: searchTerm.trim(),
      };
      dispatch(globalSearchItem(params));
    }
  }

  useDebounce(
    () => {
      fetchData();
    },
    [searchTerm],
    800
  );

  const onChangeSearch = (event) => {
    let val = event.target.value;
    setSearchItem(val);
    setSearchSuggestion(true);
    if (event.target.value === "") {
      dispatch({ type: "GLOBAL_SEARCH_QUERY_RESULT_RESET" });
    }
    var imageClass = document.getElementsByClassName('searchBackground');
    for (var i = 0; i < imageClass.length; i++) {
      imageClass[i].onclick = () => {
        setSearchSuggestion(false);
        setSearchItem('');
      }
    }
  };

  const redirectToSearchResult = (selectedSearchQuery) => {
    dispatch({ type: "GLOBAL_SEARCH_QUERY_RESULT_RESET" });
    let parentModuleIndex = activeBusinessUnit.findIndex((bu) => bu.parentModule === selectedSearchQuery.viewName);
    let buTypeIndex = activeBusinessUnit.findIndex((bu) => (bu.parentModule === selectedSearchQuery.buType || bu.parentModule === selectedSearchQuery.Name));
    console.log("redirectToSearchResultActiveBusinessUnit", 111,
      activeBusinessUnit, selectedSearchQuery, parentModuleIndex, buTypeIndex);
    if (parentModuleIndex > -1) {
      let parentModule = activeBusinessUnit[parentModuleIndex];
      let activeModule = parentModule.childModule.find(
        (childBU) => childBU.title.trim() === selectedSearchQuery.displayName.trim()
      );
      console.log(
        "redirectToSearchResultActiveBusinessUnit", 222,
        selectedSearchQuery, parentModule.childModule, activeModule, selectedSearchQuery.displayName
      );
      let path = activeModule.path;
      props.navigationPath(path, activeModule, parentModule, parentModuleIndex);
      sessionStorage.setItem("region", selectedSearchQuery.region);
      sessionStorage.setItem("subRegion", selectedSearchQuery.subRegion);
    }
    else if (buTypeIndex > -1) {
      let parentModule = activeBusinessUnit[buTypeIndex];
      let activeModuleIndex = parentModule.childModule.findIndex(
        (childBU) => childBU.title.trim() === selectedSearchQuery.viewName.trim()
      );
      console.log(
        "redirectToSearchResultActiveBusinessUnit", 333,
        selectedSearchQuery, parentModule.childModule, selectedSearchQuery.viewName, activeModuleIndex
      );
      if (activeModuleIndex > -1) {
        let activeModule = parentModule.childModule[activeModuleIndex];
        let path = activeModule.path;
        props.navigationPath(path, activeModule, parentModule, parentModuleIndex);
        sessionStorage.setItem("region", selectedSearchQuery.region);
        sessionStorage.setItem("subRegion", selectedSearchQuery.subRegion);
      }
    }
  };

  const renderTitle = (report) => {
    const { displayName, viewName, /* subRegion, region  */ } = report;
    if (displayName) {
      return (
        <Grid item xs={12} className={classes.flexRow} style={{ borderBottom: '1px solid #e5e5e5' }} onClick={() => redirectToSearchResult(report)}>
          <div className="suggestion-styling">{viewName + " > " + displayName}</div>
          <div className={`viewSearchReport`} >
            View Report
          </div>
        </Grid>
      )
    } else {
      return (
        <Grid item xs={12} className={classes.flexRow} style={{ borderBottom: '1px solid #e5e5e5' }} onClick={() => redirectToSearchResult(report)}>
          <div className="suggestion-styling">{viewName}</div>
          <div className={`viewSearchReport`}>
            View Report
          </div>
        </Grid>
      )
    }
    // if (displayName && subRegion !== "null") {
    //   return (
    //     <Grid item xs={12} className={classes.flexRow} style={{borderBottom: '1px solid #e5e5e5'}}>
    //       <div className="suggestion-styling">{region + " > " + subRegion + " > " + viewName + " > " + displayName}</div>
    //       <div className={`viewSearchReport`} onClick={() => redirectToSearchResult(report)}>
    //         View Report
    //       </div>
    //     </Grid>
    //   )
    // } else
  };

  const handleSearchSuggestionOff = () => {
    setSearchSuggestion(false);
  }


  const renderSearchResult = () => {
    let { data, loading } = searchResult;
    if (loading) {
      return <div>...loading</div>;
    } else if (Array.isArray(data) && data.length > 0 && searchSuggestion) {
      let uniqueData = utils.uniqueArrayData(data, 'displayName')
      return (
        <Grid item xs={10} className="searchSuggestion" onClick={handleSearchSuggestionOff}>
          {
            uniqueData.filter((el) => el.region === activeRegion || el.region === 'Global').map((el) => (
              <Grid
                item xs={12}
                className="suggesSearch"
                onClick={() => redirectToSearchResult(el)}
              >
                {renderTitle(el)}
              </Grid>
            ))}
        </Grid>
      );
    }
    // else if (Array.isArray(data) && data.length === 0 && searchTerm !== '') {
    //   return (
    //     <Grid item xs={10} className="searchSuggestion">
    //       <div>{`There is no data available for ${activeRegion}`}</div>
    //     </Grid>
    //   );
    // }
  };

  // const searchStyle= {
  //   backgroundImage:`url(${"../../assets/icons/error.svg"})`,
  //   backgroundSize: '40px 22px',
  //   backgroundRepeat: ' no-repeat',
  //   backgroundAttachment: 'fixed',
  //   backgroundPosition: 'right'
  //   ,
  // }

  const renderSearchInput = () => {
    return (
      <Grid container item xs={12} className={classes.searchCenterAligned}>
        <input
          value={searchTerm}
          type={"search"}
          name={"search"}
          className={`globalSearch ${searchSuggestion ? 'searchBackground' : null}`}
          onChange={(e) => onChangeSearch(e, true)}
          autoComplete="false"
          placeholder={`Type a keyword to find a report that can provide the analysis you need`}
        />
        {renderSearchResult()}
      </Grid>
    );
  };

  return (
    <Grid container item xs={12} className={classes.flexContainer}>
      {renderSearchInput()}
    </Grid>
  );
};

export default GlobalSearch;
