import React, { useEffect, useCallback, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useStyles } from "./style.js";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import {
    fetchShareablePlayBookLinks,
    updatesidebarMenuList,
    updateRegionSpecificConfigs,
    updateReportContent,
    updateBookmarkViews,
    updateActiveBusinessUnit,
    updateActivePage,
} from "../../redux/actions/index";
import {
    createTheme,
    MuiThemeProvider,
} from "@material-ui/core/styles";
import arrowRight from '../../assets/icons/arrowRight.svg';
import analytics from '../../assets/icons/Self Service Analytics.svg';
import foundationAnalytics from '../../assets/icons/foundationAnalytics.svg';
import resources from '../../assets/icons/resources.svg';
import strategicPricing from '../../assets/icons/Strategic Pricing.svg';
import PPA from '../../assets/icons/Price Pack Architecture.svg';
import mixm from '../../assets/icons/Mix Management.svg';
import tprom from '../../assets/icons/Trade Promotions.svg';
import cpol from '../../assets/icons/Commercial Policy.svg';
import additional from '../../assets/icons/Customized Reports.svg';
import CT from '../../assets/icons/control tower.svg';
import dartboards from '../../assets/icons/dartboards.svg';
import utils from '../../utils';
import './style.css';
import GlobalSearch from "../GlobalSearch/index.js";
import Popover from '@material-ui/core/Popover';

const BusinessUnit = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();

    const ShareLinkData = useSelector((state) => state.fetchShareLinkDetails);
    const oktaAuth = useSelector((state) => state.oktaAuth);
    const selectedRegion = useSelector((state) => state.getBusinessUnitsRegion);
    // const errorMessage = useSelector((state) => state.getErrorMessages);

    const { activeBusinessUnit, activeRoutes, activeRegion,   /*activeRegionSpecificConfigs*/ } = useSelector((state) => state.activeDynamicData);
    const { regionList } = selectedRegion;

    const container = useRef(null);
 

    // const [aboutRGM, setAboutRGM] = useState('');
    const [atBottom, setAtBottom] = useState(false);
    const [anchorEl, setAnchorEl] =  useState(null);
    const [openedPopoverId, setOpenedPopoverId] =  useState(null);

    
    const open = Boolean(anchorEl);

    const popoverTop = anchorEl?.getBoundingClientRect().top || 0;
    const popoverLeft = anchorEl?.getBoundingClientRect().left || 0;

    let imageList = {
        analytics,
        foundationAnalytics,
        resources,
        arrowRight,
        strategicPricing,
        PPA,
        mixm,
        tprom,
        cpol,
        additional,
        CT,
        dartboards
    };

    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: "1em",
                    color: "#fff",
                    fontFamily: 'Gilroy-Medium',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'/* 142.857% */,
                    marginBottom: '0px',
                    padding: '5px 10px',
                    zIndex: 1,
                    background: '#000'
                    // position: "absolute",
                    // right: '10px'
                }
            }
        }
    });

    React.useEffect(() => {
        window.addEventListener('scroll', handleScroll, {
            passive: true
        });

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        let data = sessionStorage.getItem('presistReport');
        const shareState = !!data ? JSON.parse(data) : null;
        if (!!shareState && Object.keys(shareState).length > 0 && shareState.type === 'share') {
            let params = { shareLinkID: shareState.shareId, hashedId: shareState.hashId }
            dispatch(fetchShareablePlayBookLinks(params));
        }
    }, [ dispatch]);

    const viewShareFeatureTrack = (ShareLink, finalBU) => {
        let trackingFilter = {};
        if (finalBU && !!finalBU['globalFilters']) {
            const dataSet = !!ShareLink.selectedFilters && JSON.parse(ShareLink.selectedFilters)['dataSet']
            !!dataSet && Object.keys(dataSet).map((item) => trackingFilter[item] = dataSet[item]['activeItem']);
        };
        utils.userTrackingInfo({
            region: ShareLink.region,
            subRegion: ShareLink.subRegion,
            actionType: 'Share',
            pageName: ShareLink.pageName,
            reportName: ShareLink.viewName,
            reportSection: ShareLink.reportSectionName,
            globalFilters: JSON.stringify(trackingFilter),
            reportSectionId: ShareLink.reportSectionId,
            groupId: ShareLink.groupId,
            reportId: ShareLink.reportId,
            reportLink: `${process.env.REACT_APP_APP_URL}/share-playbooks-report?uuid=${ShareLink['shareLinkID']}&linkid=${ShareLink['hashedId']}`,
            atributeName: 'View Shared Report'
        });
    };

    const updateRegionSpecificCustomData = useCallback((shareLink) => {
        let selectedRegion = shareLink.region;
        let index = regionList.findIndex((item) => item.region === selectedRegion);
        if (index > -1) {
            let selectedRegionData = regionList[index];
            let finalRoutes = selectedRegionData['routes'];
            let disabledScreen = selectedRegionData['disabledScreen']
            let parentModule = selectedRegionData['businessUnits'];
            let parentModuleIndex = parentModule.findIndex((el) => el.key === shareLink.pageType);
            let activeParentModule = parentModuleIndex > -1 ? parentModule[parentModuleIndex] : parentModule[0]
            let childModuleIndex = activeParentModule.childModule.findIndex((el) => el.key === shareLink.pageType)
            let childModule = childModuleIndex > -1 ? activeParentModule.childModule[childModuleIndex] : activeParentModule.childModule[0]
            if (childModule) {
                dispatch(updateActivePage(childModule))
                sessionStorage.setItem('ActivePage', JSON.stringify(childModule));
            }
            console.log('updateRegionSpecificConfigsRegion', selectedRegion);
            dispatch(updateRegionSpecificConfigs(
                parentModule,
                finalRoutes,
                disabledScreen,
                {
                    showFeedback: selectedRegionData ? selectedRegionData['showFeedback'] : false,
                    msalTokenEnabled: selectedRegionData ? selectedRegionData['msalTokenEnabled'] : false,
                    homePageRedirectKey: selectedRegionData ? selectedRegionData['homePageRedirectKey'] : null,
                    showDashboardPage: selectedRegionData ? selectedRegionData['showDashboardPage'] : false,
                    showUpdateMessage: selectedRegionData ? selectedRegionData['showUpdateMessage'] : false,
                    parentPath: parentModule.key,
                },
                selectedRegion
            ))
            return { finalBusinessUnit: parentModule, finalRoutes: finalRoutes };
        }
    }, [dispatch, regionList]);

    useEffect(() => {
        let ShareLink = ShareLinkData && ShareLinkData.data[0];
        let presistReport = sessionStorage.getItem('presistReport');
        const shareState = !!presistReport ? JSON.parse(presistReport) : null;
        let parentId;
        let childId;
        if (!!ShareLink && Object.keys(ShareLink).length > 0 && !!shareState && shareState.type === "share") {
            let nextPathData = updateRegionSpecificCustomData(ShareLink);
            if (ShareLink.otherDetails) {
                let otherDetails = JSON.parse(ShareLink.otherDetails);
                parentId = otherDetails.parentId;
                childId = otherDetails.childId;
            }
            let activeParentModule = nextPathData['finalBusinessUnit'].filter((item) => item.parentId === parentId)[0];
            let activeChildModule = activeParentModule ?
                activeParentModule.childModule.find((el) => el.childId === childId) :
                nextPathData['finalBusinessUnit'][0].childModule;
            console.log('shareLinkfunction', nextPathData, activeParentModule, ShareLink, activeChildModule, parentId, childId)
            const params = {
                background: "#f4f5f6",
                pageType: ShareLink.pageType,
                pageName: ShareLink.pageName,
                region: ShareLink.region,
                subRegion: ShareLink.subRegion,
                sideBar: "",
                viewName: ShareLink.viewName,
                viewIndex: ShareLink.viewIndex,
                activeReport: ShareLink.reportSectionName,
                type: 'ShareReport',
                businessUnitData: activeChildModule,
            };
            if (!!oktaAuth.auth) {
                history.push({
                    pathname: activeChildModule.path,
                    state: params
                });
                let presistState = {
                    type: 'share',
                    state: ShareLink.shareLinkState,
                    shareId: ShareLink.shareLinkID,
                    hashId: ShareLink.hashedId
                };
                sessionStorage.setItem('region', ShareLink.region);
                sessionStorage.setItem('subRegion', ShareLink.subRegion);
                sessionStorage.setItem('presistReport', JSON.stringify(presistState));
                viewShareFeatureTrack(ShareLink, activeChildModule);
                dispatch(updateBookmarkViews(ShareLink));
                dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_REQUEST" });
            }
        }
    }, [ShareLinkData, history, oktaAuth, activeBusinessUnit, activeRoutes.key1, updateRegionSpecificCustomData, dispatch]);

    // let openNewTab = (e, urlparams) => {
    //     let finalBU = activeBusinessUnit.filter((item) => { if (item.key === pageType) return item })[0]
    //     if (finalBU['openNewTabFeature']) {
    //       if (e.ctrlKey || e.metaKey) {
    //         window.open(`${process.env.REACT_APP_APP_URL}/dartboards-new-tab?${urlparams}`, "_blank");
    //         return false
    //       }else
    //       return true
    //     }
    //     return true
    //   }

    const NavigateTo = (pathName, activeModule, parentModule, parentIndex, childIndex, report) => {
        const { region, subRegion } = props;
        let  urlparams;
        if(report){
            urlparams = `prntMdlpos=${parentIndex}&&prntMd=${activeModule.parentModule}&&actvRprt=${activeModule.title}&&actvRprtpos=${childIndex}&&actvRprtId=${report}&&actvRprtky=${activeModule.key}&&rgncon=${activeRegion}&&sbrgncon=${subRegion}&&rptPge=${true}&&prntId=${parentModule.parentId}&&chldId=${activeModule.childId}`
           setAnchorEl(null);
        }
        else{
            urlparams = `prntMdlpos=${parentIndex}&&prntMd=${activeModule.parentModule}&&actvRprt=${activeModule.title}&&actvRprtpos=${childIndex}&&actvRprtId=${activeModule.background}&&actvRprtky=${activeModule.key}&&rgncon=${activeRegion}&&sbrgncon=${subRegion}&&rptPge=${false}&&prntId=${parentModule.parentId}&&chldId=${activeModule.childId}`
        }
        console.log('NavigateTo', pathName, activeModule, parentModule, parentIndex, childIndex, urlparams, report);
        const params = {
            pageType: activeModule.key,
            pageName: activeModule.parentModule,
            sideBar: activeModule.sideBarKey,
            businessUnitData: parentModule,
            region: region,
            subRegion: subRegion,
            background: activeModule.background,
            reportSectionId: activeModule["name"]
        };
        activeModule['activeParentIndex'] = parentIndex;
        if (activeModule.embedLinkType && activeModule.link !== '') {
            window.open(
                activeModule.path,
                "_blank",
            );
        }
        else {
            if(activeModule.key === "Self Service" || activeModule.key === "Links & Trainings"){
                history.push({
                    pathname: pathName,
                    state: params
                });
            }
            else{
                window.open(`${process.env.REACT_APP_APP_URL}${pathName}?${urlparams}`, "_blank");
            }
        }
        setAnchorEl(null);
        setOpenedPopoverId(null);
        dispatch(updateActivePage(activeModule))
        sessionStorage.setItem('ActivePage', JSON.stringify(activeModule));
        dispatch(updatesidebarMenuList([]));
        dispatch(updateReportContent({}));
        dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_REQUEST" });
        dispatch({ type: "GET_DYNAMIC_REPORT_CONTENT_REQUEST" });
        utils.userTrackingInfo({
            region: region,
            actionType: 'Click',
            pageName: activeModule.pageName,
            pageDetails: `Redirected from Home to ${activeModule.pageName}`,
            reportName: activeModule.parentModule,
            reportSection: activeModule.title,
            subRegion: subRegion,
        });
    };

    const collpaseChildModule = (index) => {
        let activeParentModule = Array.isArray(activeBusinessUnit) && activeBusinessUnit.length > 0 && activeBusinessUnit[index]
        activeParentModule.collapse = !activeParentModule.collapse;
        dispatch(updateActiveBusinessUnit(activeBusinessUnit));
    };


    const handlePopoverOpen = (event, childId) => {
        setAnchorEl(event.currentTarget);
        setOpenedPopoverId(childId);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const renderChildModule = (parentModule, index, childModule, childIndex) => {
        if (childModule.sidebar) {
            // console.log('setOpenedPopoverId', openedPopoverId, childModule.childId)
            return (
                <Grid container item key={childIndex} xs={12} className={classes.childModule}       >
                    <Grid className={classes.flexRow} key={childIndex}>
                        <Grid className={classes.contentBox} key={childIndex}>
                            <Typography
                                aria-owns={open ? `${"mouse-over-popover" + childModule.childId}` : undefined}
                                aria-haspopup="true"
                                onMouseOver={(e)=>handlePopoverOpen(e, childModule.childId)}
                                variant="subtitle2"
                                className={classes.subtitle}
                            >
                                {childModule.title}
                            </Typography>
                            <Popover
                                id={`${"mouse-over-popover" + childModule.childId}`}
                                sx={{ pointerEvents: 'cursor' }}
                                open={open && openedPopoverId === childModule.childId}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                anchorPosition={{ top: popoverTop, left: popoverLeft }}
                                onMouseLeave={handlePopoverClose}
                            >
                                {
                                    Array.isArray(childModule.sideBarKey) && childModule.sideBarKey.length > 0 &&
                                    childModule.sideBarKey.map((report, index) =>
                                        <Typography sx={{ p: 1 }} key={childModule.childId} variant="body2" className="suggestion-styling"   
                                             onClick={() => NavigateTo(childModule.path, childModule, parentModule, index, childIndex, report)}  
                                        >
                                            {report}
                                        </Typography>
                                    )
                                }
                            </Popover>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
        else {
            return (
                <Grid container item key={childIndex} xs={12} className={classes.childModule} onClick={() => NavigateTo(childModule.path, childModule, parentModule, index, childIndex)}>
                    <Grid className={classes.flexRow}>
                        <Grid className={classes.contentBox}>
                            <MuiThemeProvider theme={theme}>
                                <Tooltip title={childModule.description}>
                                    <Typography variant="subtitle2" className={classes.subtitle}>
                                        {childModule.title}
                                    </Typography>
                                </Tooltip>
                            </MuiThemeProvider>
                        </Grid>
                    </Grid>
                </Grid>
            )
        }
    }

    const renderBusinessUnit = () => {
        let data = activeBusinessUnit.filter((el) => el.viewStatus == 'Active');
        if (Array.isArray(data) && data.length > 0) {
            return (
                data.sort((function (a, b) { return a.parentOrder - b.parentOrder })).map((units, index) =>
                (
                    <Grid container item className={classes.parentModulePosition} key={index + units.title} xs={2}>
                        <Grid style={{ background: units.background }} className={classes.parentModule}>
                            <Grid item xs={3} className={classes.logoBox} onClick={() => collpaseChildModule(index)}>
                                <img className={classes.parentImg} alt="complex" src={imageList[units.image]} />
                            </Grid>
                            <Grid item xs={9} className={classes.parentContent}>
                                <Typography gutterBottom variant="h5" className={classes.ParentTitle}>
                                    {units.title}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid container item className={classes.childModulePosition} key={index + units.title} xs={12}  onClick={handlePopoverClose}>
                            {!units.collapse ?
                                units.childModule.length > 0 &&
                                units.childModule.sort((function (a, b) { return a.childOrder - b.childOrder })) &&
                                units.childModule.filter((el) => el.subReport).map((unit, childIndex) =>
                                (
                                    renderChildModule(units, index, unit, childIndex)
                                ))
                                :
                                (
                                    <Grid container item key={index} xs={12} className={classes.childModule}>
                                        <Grid className={classes.flexRow}>
                                            <Grid className={classes.contentBox}>
                                                <Typography gutterBottom variant="subtitle2" className={classes.subtitle}>
                                                    {units.collapseText}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )
                            }
                        </Grid>
                    </Grid>
                )
                )
            )
        }
    };

    const scrollToBottom = () => {
        window.scrollTo(0, container.current.offsetHeight);
    }

    const scrollToTop = () => {
        window.scrollTo(0, 0);
    }


    const handleScroll = () => {

        const bottom = Math.ceil(window.innerHeight + window.scrollY) >= document.documentElement.scrollHeight

        if (bottom) {
            console.log('at the bottom', atBottom);
            setAtBottom(true);
        }
        else {
            setAtBottom(false);
        }
    };

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container item xs={12} className={classes.flexContainer}>
                    <Grid container item xs={1}></Grid>
                    <Grid container item xs={10}>
                        <Grid item xs={12} className="appName">
                            RGM Command Center
                        </Grid>
                        <Grid container item xs={12} >
                            {/* {aboutRGM ? aboutRGM : `Welcome to our RGM Command Center. Here, you'll find a variety of dashboards that will provide valuable insights, helping you to make better data-driven decisions`} */}
                            <GlobalSearch 
                                subRegion={props.subRegion} 
                                navigationPath={(path, activeModule, parentModule, parentModuleIndex) => NavigateTo(path, activeModule, parentModule, parentModuleIndex)} 
                            />
                        </Grid>
                        <Grid container item className={classes.centerAlignModulePos} xs={12} ref={container}>
                            {renderBusinessUnit()}
                        </Grid>
                    </Grid>
                    <Grid container item xs={1}></Grid>
                    <div className={`arrowCircle ${atBottom ? "posFixed" : null}`} onClick={() => atBottom ? scrollToTop() : scrollToBottom()}>
                        {
                            atBottom ?
                                <div className={`upper-arrow bounce`} ></div> :
                                <div className={`down-arrow bounce`}  ></div>
                        }
                    </div>
                    <Grid item xs={12} className={classes.righReserved}>
                        © 2001-2020 KCWW. All Rights Reserved.
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

export default BusinessUnit;