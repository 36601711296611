const defaultState = {
    success: false,
    responseDeafult: true,
    error: null,
    description: [],
    sidebarLoading :false
  }

  export default (state = defaultState, action) => {

    switch (action.type) {

      case 'GET_SIDEBAR_MENU_LIST_REQUEST':
        return {
          ...defaultState,
          sidebarLoading :true
        }

      case 'GET_SIDEBAR_MENU_LIST_SUCCESS':
        return {
          ...defaultState,
          success: true,
          description: action.payload.data.data,
          responseDeafult: false,
          error: null,
          sidebarLoading :false
        }

      case 'GET_SIDEBAR_MENU_LIST_FAILURE':
        return {
          ...defaultState,
          success: false,
          error: action.payload,
          message: action.payload.response.data['message'],
          userMessage: action.payload.response.data['usermessage'],
          responseDeafult: false,
          description: [],
          sidebarLoading :false
        }

      default:
        return {
          ...state,
        };
    }
  };
