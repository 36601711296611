import {
  updateMsalToken,
  updateMsalTokenFlag,
} from "../../redux/actions/index";
import store from "../../redux/store";


export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_MSAL_CLIENT_ID,
    authority: process.env.REACT_APP_MSAL_AUTH_URL,
    redirectUri: process.env.REACT_APP_MSAL_REDIRECT_URL,
  },
};

export const loginRequest = {
  scopes: ["https://analysis.windows.net/powerbi/api/Report.Read.All"],
};

export const storeMsalToken = (accessToken) => {
  // console.log("i am in mount","storeMsalToken", accessToken);
  store.dispatch(updateMsalToken(accessToken));
  store.dispatch(updateMsalTokenFlag(false, "loginreq"));
};

export const mslLogin = (msalInstance, history) => {
  // Create UserAgentApplication instance
  let sessionData = JSON.parse(sessionStorage.getItem("oktaRes"));
  if (sessionData && sessionData.email) {
    msalConfig["loginHint"] = sessionData.email;
    loginRequest["loginHint"] = sessionData.email;
  }

  function authCallback(error, response) {
    // console.log("i am in mount", "authcall error ", error, " success response ", response);
    // Handle redirect response
  }

  msalInstance.handleRedirectCallback(authCallback);
  if (msalInstance.getAccount()) {
    // console.log("i am in mount", "acquireTokenSilent");
    msalInstance
      .acquireTokenSilent(loginRequest)
      .then((response) => {
        // console.log("i am in mount","acquireTokenSilent response", response);
        sessionStorage.setItem("msalAccessTokenExpiry", response.expiresOn);
        sessionStorage.setItem("msalAccessToken", response.accessToken);
        storeMsalToken(response.accessToken);
        history.push({
          pathname: "/",
          state: {
            type: "msalcallback",
            msalAccessToken: response.accessToken,
          },
        });

        //window.location.replace('/');
        //   fetch("https://api.powerbi.com/v1.0/myorg/RefreshUserPermissions", {
        //     headers: {
        //         "Authorization": "Bearer " + response.accessToken
        //     },
        //     method: "POST"
        // })
        // .then(function (response) {
        //     if (response.ok) {
        //         console.log("User permissions refreshed successfully.");
        //     } else {
        //         if (response.status === 429) {
        //             console.error("Permissions refresh will be available in up to an hour.");
        //         } else {
        //             console.error(response);
        //         }
        //     }
        // })
        // .catch(function (error) {
        //     console.error("Failure in making API call." + error);
        // });
      })
      .catch((err) => {
        if (err.name === "InteractionRequiredAuthError") {
          msalInstance.acquireTokenRedirect(loginRequest);
        }
      });
  } else {
    // console.log("i am in mount","loginRedirect");
    msalInstance
      .loginRedirect(loginRequest)
      .then(function (loginResponse) {
        // console.log("i am in mount","loginRedirect response", loginResponse);
        // Display signed-in user content, call API, etc.
      })
      .catch(function (error) {
        //login failure
        // console.log("i am in mount","loginRedirect error", error);
      });
  }
};

export const handleSilentLogin = (msalInstance, loginRequest) => {
  // console.log("i am in mount","handleSilentLogin");

  if (msalInstance.getAccount()) {
    let sessionData = JSON.parse(sessionStorage.getItem("oktaRes"));
    // console.log("i am in mount","sessiondata silent login", sessionData);
    if (sessionData && sessionData.email) {
      loginRequest["loginHint"] = sessionData.email;
    }
    msalInstance
      .acquireTokenSilent(loginRequest)
      .then((response) => {
        // console.log("i am in mount","handleSilentLogin-acquireTokenSilent",   response   );
        sessionStorage.setItem("msalAccessTokenExpiry", response.expiresOn);
        sessionStorage.setItem("msalAccessToken", response.accessToken);
        storeMsalToken(response.accessToken);
      })
      .catch((err) => {
        if (err.name === "InteractionRequiredAuthError") {
          msalInstance.acquireTokenRedirect(loginRequest);
        }
      });
  }
};

export const handleSilentLoginRedirect = (
  msalInstance,
  loginRequest,
  history
) => {
  // console.log("i am in mount","handleSilentLoginRedirect");

  if (msalInstance.getAccount()) {
    let sessionData = JSON.parse(sessionStorage.getItem("oktaRes"));
    if (sessionData && sessionData.email) {
      loginRequest["loginHint"] = sessionData.email;
    }
    // console.log("i am in mount","handleSilentLoginRedirect msalInstance getaccount", loginRequest);
    msalInstance
      .acquireTokenSilent(loginRequest)
      .then((response) => {
        // console.log( "i am in mount", "in silenr login succes handleSilentLoginRedirect",response);
        sessionStorage.setItem("msalAccessTokenExpiry", response.expiresOn);
        sessionStorage.setItem("msalAccessToken", response.accessToken);
        //storeMsalToken(response.accessToken)
        history.push({
          pathname: "/",
          state: {
            type: "msalcallback",
            msalAccessToken: response.accessToken,
          },
        });

        //  window.location.replace('/');
      })
      .catch((err) => {
        // console.log( "i am in mount", "in silenr login error handleSilentLoginRedirect",err, err.name  );
        if (err.name === "InteractionRequiredAuthError" || err.name === "ClientAuthError") {
          msalInstance.acquireTokenRedirect(loginRequest);
        }
      });
  }
};
