import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UserAgentApplication } from "msal";
import { handleSilentLogin, loginRequest, msalConfig } from "../../component/msal/loginrequest";
import { Report } from 'powerbi-report-component';
import { models } from 'powerbi-client';
import cloneDeep from 'lodash/cloneDeep';
import { useStyles } from "../reports/style";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import BarLoader from "react-spinners/BarLoader";
import {
  updateMsalTokenFlag,
  toastTriggered,
  getEmbedToken,
  getMsalBasedPlaybookContentPages,
  getPlayBooksContentPages,
  updatePowerBIReport,
  updatePlaybooksContentPages,
  insertNewSubReport,
  getSelectedSubReportDetails,
  updateSelectedSubReport,
  updateJsonForView,
  getPlaybooksubReport
} from '../../redux/actions/index';
import CircularProgress from '@material-ui/core/CircularProgress';
import utils from '../../utils/index';
import { useHistory } from "react-router-dom";
import toastProperties from "../../assets/data/toastProperty";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { PlusCircleFill } from '../../assets/icons/Icons';
import Modal from "../../component/modal";
import "./Admin.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { EditIcon } from '../../assets/icons/Icons';
import TagsInput from '../../component/tagsInput';


const PreviewDashboard = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  let history = useHistory()

  const { pageType, navContentPaneEnabled } = props;

  const powerbiToken = useSelector((state) => state.getEmbedToken);
  const errorMessage = useSelector((state) => state.getErrorMessages);
  const playBookData = useSelector((state) => state.viewBuilderObj);
  const playbookContentPages = useSelector((state) => state.playbookContentPages);
  const groupedSubReport = useSelector((state) => state.subReportDetails);
  const InsertNewSubReport = useSelector((state) => state.InsertNewSubReport);

  const [embedError, setEmbedError] = React.useState(null);
  const [showSettingsWindow, setShowSettingWindow] = React.useState(false);
  const [reportIDs, setReportIDs] = useState([]);
  const [currentReport, setCurrentReport] = useState(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const [newReport, setNewReport] = useState(false);
  const [displayName, setDisplayName] = useState('');
  const [viewDescription, setViewDescription] = useState('');
  const [linkForDocumentation, setLinkForDocumentation] = useState('');
  const [htmlTemp, setHtmlTemp] = useState({});
  const [tagsKeyword, setTagsKeyword] = useState([]);
  const [isReportPages, setIsReportPages] = useState(true);
  const [isGroupedReport, setIsGroupedReport] = useState(false);
  const [groupedReportPages, setGroupedReportPages] = useState([]);
  const [fetchPages, setFetchPages] = useState(0)
  const [reportSectionPages, setReportPages] = useState([]);
  const [reportPageId, setReportPageId] = useState('');
  const [customizedReport, setCustomizedReport] = useState(false);
  const [viewStatus, setviewStatus] = useState('')


  const { activePage, isMsalTokenExpired, activeRegion, /*activeRegionSpecificConfigs*/ } = useSelector((state) => state.activeDynamicData);
  const msalInstance = new UserAgentApplication(msalConfig);

  useEffect(() => {
    const PBIDown = errorMessage?.data?.find(item => item.errorType === "PBI API Down")
    setEmbedError(PBIDown?.errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    let activeReport = playBookData.viewBuilderJSON;
    if (activeReport && activeReport.region === activeRegion) {
      let { msalEnabled, grouped, reportPages, hiddenPages, htmlTemp, subReport, customizableFlag } = activeReport;
      if (!msalEnabled && !grouped) {
        let params = {
          groupId: htmlTemp.groupId,
          reportId: htmlTemp.reportId
        }
        dispatch(getEmbedToken(params));
      }
      if (grouped  && subReport.length === 0) {
        const params = {
          viewID: activeReport['viewId'],
          pageType: 'playbooksPreview',
          buType: activeReport.buType
        };
        dispatch(getPlaybooksubReport(params));
      }
      if (!grouped && reportPages && !hiddenPages && subReport.length === 0) {
        let params = utils.checkValidPBIds(htmlTemp, customizableFlag);
        dispatch(getPlayBooksContentPages(params));
      }
    }
  }, [dispatch, activeRegion, playBookData.viewBuilderJSON]);

  useEffect(() => {
    let tempViewBuilderObj = cloneDeep(playBookData.viewBuilderJSON);
    const { htmlTemp, grouped } = tempViewBuilderObj;
     const { data } = playbookContentPages;

    let reportsIds = utils.checkValidPBIds(htmlTemp, tempViewBuilderObj.customizableFlag);
    setReportIDs(reportsIds);
    if (reportsIds) {
      if (grouped && Array.isArray(data) && data.length > 0) {
        let { htmlTemp, customizableFlag } = data[0];
        let reportsIds = utils.checkValidPBIds(htmlTemp, customizableFlag);
        setReportIDs(reportsIds)
        setHtmlTemp(htmlTemp);
        dispatch(getEmbedToken(reportsIds));
      }
      else {
        setReportIDs(reportsIds)
        setHtmlTemp(htmlTemp)
      }
    }
    else {
      setReportIDs({})
    }
  }, [dispatch, playBookData.viewBuilderJSON, playbookContentPages]);

  const UpdateReportPages = (report) => {
    console.log();
    const { hiddenPages, reportPages, viewId, grouped } = playBookData.viewBuilderJSON;
    dispatch(updatePowerBIReport(report));
    if (Object.keys(report).length > 0) {
      report.getPages().then((allPage) => {
        let data = Array.isArray(allPage) && allPage.length > 0 &&
          allPage.filter((pages) => {
            return pages.visibility === 0
          });
        data.forEach((el, index) => el.order = index);
        if (Array.isArray(data) && data.length > 0) {
          let res = {
            data
          }
          let selections = {
            reportId: reportIDs['reportId'],
            groupId: reportIDs['groupId'],
            viewID: viewId,
            pageType: activePage.key,
          }
          let payload = { ...res, selections };
          if (reportPages && hiddenPages) {
            dispatch(updatePlaybooksContentPages(payload));
          }
          else if (grouped) {
            setReportPages(data)
          }
        }
      })
    }
  };

  const layoutSettings = {
    // layoutType: models.LayoutType.Custom,
    customLayout: {
      pageSize: {
        type: models.PageSizeType.Custom,
        width: 1200,
        height: 5900
      },
      displayOption: models.DisplayOption.FitToWidth,
      pagesLayout: {
        ReportSection1: {
          defaultLayout: {
            displayState: {
              mode: models.VisualContainerDisplayMode.Hidden,
            },
          },
          visualsLayout: {
            VisualContainer1: {
              x: 1,
              y: 1,
              z: 1,
              width: 900,
              height: 300,
              displayState: {
                mode: models.VisualContainerDisplayMode.Visible,
              },
            },
            VisualContainer2: {
              displayState: {
                mode: models.VisualContainerDisplayMode.Visible,
              },
            },
          },
        },
      },
    },
  };

  const extraSettings = {
    filterPaneEnabled: false, //true
    navContentPaneEnabled: navContentPaneEnabled, //true
    hideErrors: false, // ,
    ...layoutSettings,  //  layout config
  };


  const style = {
    height: "100vh",
    width: "inherit",
    fontSize: "80%",
  };

  const handlePageChange = (data, groupId, reportId) => {
    let trackingObject = {
      region: activeRegion,
      actionType: 'Click',
      pageName: pageType ? pageType : activePage && activePage.key,
      reportName: playBookData.viewName,
      reportSection: data.newPage.displayName,
      reportSectionId: data.newPage.name,
      groupId: groupId,
      reportId: reportId,
    };
    if (pageType === "Dashboard") {
      trackingObject.actionType = "Admin Activity";
      trackingObject.attributeName = 'Dashboard Embbeded Report Preview';
    }
    utils.userTrackingInfo(trackingObject);
  };

  const handleTokenExpireError = () => {
    if (process.env.REACT_APP_ENVIRONMENT === "LOCAL") {
      toastProperties.error["description"] = 'MSAL Access Token is expired, Please get a new token';
      dispatch(toastTriggered([toastProperties.error]));
    }
    else if (msalInstance.getAccount()) {
      dispatch(updateMsalTokenFlag(true, "default repo"))
      handleSilentLogin(msalInstance, loginRequest);
    } else {
      history.push({
        pathname: "/msalcallback",
        state: {},
      });
    }
  };

  const selectActiveSubMenu = (index, text, settingWindow) => {
    if (settingWindow) {
      setShowSettingWindow(true);
      let params = { subReportId: text.subReportId, region: text.region }
      dispatch(getSelectedSubReportDetails(params));
      setDisplayName(text.displayName);
      setViewDescription(text.viewDescription);
      setLinkForDocumentation(text.linkForDocumentation);
      setHtmlTemp(text.htmlTemp);
      setNewReport(false);
      setTagsKeyword(text.tags);
      setviewStatus(text.viewStatus)
      if (!text.reportPage) {
        setIsReportPages(false);
        setCustomizedReport(true);
      }
    }
    else if (!settingWindow) {
      let activeReport = playBookData.viewBuilderJSON;
      setActiveIndex(index);
      if (!activeReport.grouped) {
        currentReport.setPage(text.name);
      }
      else if (activeReport.grouped) {
        let reportsIds = utils.checkValidPBIds(text.htmlTemp, text.customizableFlag);
        setReportIDs(reportsIds);
        dispatch(getEmbedToken(reportsIds));
        setHtmlTemp(text.htmlTemp)
      }
    }
  };

  const renderSubMenu = () => {
    let { data } = playbookContentPages;
    let activeReport = playBookData.viewBuilderJSON;
    let { grouped, reportPages, hiddenPages, } = activeReport;
    data.sort(function (a, b) { return a.order - b.order });
    return (
      data.map((text, index) => (
        <ListItem
          button
          disableRipple
          key={text + index}
          className={index === activeIndex ? classes.activeSubMenuList : classes.menuList}
        >
          <div className="arrangeSubMenu">
            <ListItemText className="clickReport" secondary={ text.reportPage ? text.displayName : text.displayName + ' - ' + text.viewDescription} onClick={() => selectActiveSubMenu(index, text, false)} />
            {
              activeReport.grouped || (!grouped && reportPages && !hiddenPages) ?
                <div className="editReport" onClick={() => selectActiveSubMenu(index, text, true)} >
                  <EditIcon /> Edit
                </div>
                : null
            }
          </div>
        </ListItem>
      ))
    )
  };

  const onChangeGroupedReport = (e) => {
    let name = e.target.name;
    let val = e.target.value;
    const word = 'groups';
    const word2 = 'reports';
    if (name === 'displayName') {
      setDisplayName(val);
    }
    if (name === 'viewDescription') {
      setViewDescription(val);
    }
    else if (name === 'linkForDocumentation') {
      if (!val.includes(word) && !val.includes(word2)) {
        setLinkForDocumentation(val);
      }
      else if (val.includes(word) && val.includes(word2)) {
        const values = val.split('/');
        if (values.length > 6) {
          const validLink = Array.isArray(values) && values[6].split('?');
          let groupId = Array.isArray(values) && values[4];
          let reportId = Array.isArray(validLink) && validLink[0];
          let htmlTemp = {
            "reportId": reportId,
            "groupId": groupId,
            "workspace": `${activeRegion} RGM PLAYBOOKS - ${process.env.REACT_APP_REPORT_WORKSPACE}`,
            "type": "default"
          }
          const reportsIds = {
            "reportId": reportId,
            "groupId": groupId,
          }
          setLinkForDocumentation(val);
          setHtmlTemp(htmlTemp);
          setReportIDs(reportsIds)
        }
      }
    }
  };

  const trackingInfo = (type) => {
    let activeReport = playBookData.viewBuilderJSON;
    const { viewName, subRegion, region, } = activeReport;
    utils.userTrackingInfo({
      region: region,
      subRegion: subRegion,
      actionType: 'Admin Activity',
      pageName: 'Dashboard',
      reportName: viewName,
      pageDetails: type,
      attributeName: viewDescription,
      reportLink: linkForDocumentation,
    });
  }

  const saveNewGroupedReport = () => {
    let activeReport = playBookData.viewBuilderJSON;
    let { subReportData } = groupedSubReport;
    const word = 'groups';
    const word2 = 'reports';
    const { viewName, region, buType, reportOrder, msalEnabled, viewId, grouped, customizableFlag } = activeReport;
    if (!displayName || (!viewDescription && !linkForDocumentation)) {
      toast.error("Please enter all values", { position: toast.POSITION.RIGHT_TOP });
      return true;
    }
    if (linkForDocumentation === '') {
      let message = 'Please enter a Power Bi Link';
      toast.error(message, { position: toast.POSITION.RIGHT_TOP });
      return true;
    }
    if (!linkForDocumentation.includes(word) && !linkForDocumentation.includes(word2)) {
      let message = 'Please enter a Valid Power Bi Link';
      toast.error(message, { position: toast.POSITION.RIGHT_TOP });
      return true;
    }
    else {
      if (Object.keys(subReportData).length > 0 && subReportData.subReportId !== '' && !newReport) {
        let obj = {
          subReportId: subReportData.subReportId,
          viewName: viewName,
          parentReportID: viewId,
          buType: buType,
          displayName: displayName,
          viewDescription: viewDescription,
          viewStatus: viewStatus,
          region: region,
          linkForDocumentation: linkForDocumentation,
          subRegion: 'NULL',
          htmlTemp: htmlTemp && Object.keys(htmlTemp).length > 0 ? htmlTemp : subReportData.htmlTemp,
          order: Array.isArray(reportOrder) ? reportOrder.length + 1 : 9999,
          msalEnabled: msalEnabled,
          tagsKeyword: tagsKeyword,
          customizableFlag: htmlTemp && Object.keys(htmlTemp).length > 0 && htmlTemp.type === 'default' ? false : true,
          reportPages: isReportPages,
          reportPageId:  isReportPages ? reportPageId : groupedReportPages,
        }
        dispatch(updateSelectedSubReport(obj)).then((res, err) => {
          if (res) {
            trackingInfo();
            toast.success(res.data.message, { position: toast.POSITION.RIGHT_TOP });
            setShowSettingWindow(false)
            if (grouped) {
              let reportId = utils.checkValidPBIds(htmlTemp, customizableFlag);
              const params = {
                ...reportId,
                viewID: activeReport['viewId'],
                pageType: activeReport.buType
              };
              dispatch(getMsalBasedPlaybookContentPages(params));
              setHtmlTemp(reportId);
              setReportIDs(htmlTemp);
              if (!msalEnabled) {
                let params = {
                  groupId: htmlTemp.groupId,
                  reportId: htmlTemp.reportId
                }
                dispatch(getEmbedToken(params));
              }
            }
          }
        })
          .catch((err) => {
            toast.error(err.response.data.message, { position: toast.POSITION.RIGHT_TOP });
          });
      }
      else if (newReport) {
        let obj = {
          viewName: viewName,
          parentReportID: viewId,
          buType: buType,
          displayName: displayName,
          viewDescription: viewDescription,
          viewStatus: 'Active',
          region: region,
          linkForDocumentation: linkForDocumentation,
          subRegion: 'NULL',
          htmlTemp: htmlTemp,
          order: Array.isArray(reportOrder) ? reportOrder.length + 1 : 9999,
          msalEnabled: msalEnabled,
          tagsKeyword: tagsKeyword,
          customizableFlag: htmlTemp && Object.keys(htmlTemp).length > 0 && htmlTemp.type === 'default' ? false : true,
          reportPages: isReportPages,
          reportPageId: isReportPages ? reportPageId : groupedReportPages,
        }
        dispatch(insertNewSubReport(obj)).then((res, err) => {
          if (res) {
            trackingInfo()
            toast.success(res.data.message, { position: toast.POSITION.RIGHT_TOP });
            setShowSettingWindow(false)
            setHtmlTemp({});
            setReportIDs({});
            if (activeReport.htmlTemp && Object.keys(activeReport.htmlTemp).length > 0 && activeReport.htmlTemp.reportId === '') {
              let obj = {
                viewId: viewId,
                htmlTemp: htmlTemp,
                region: region,
                viewCreated: 'viewBuilder',
                viewName: viewName,
                msalEnabled: activeReport.msalEnabled
              }
              dispatch(updateJsonForView(obj));
            }
            if (grouped) {
              let reportId = utils.checkValidPBIds(htmlTemp, customizableFlag);
              const params = {
                ...reportId,
                viewID: activeReport['viewId'],
                pageType: activeReport.buType
              };
              dispatch(getMsalBasedPlaybookContentPages(params));
              setHtmlTemp(reportId);
              setReportIDs(htmlTemp);
              if (!msalEnabled) {
                let params = {
                  groupId: htmlTemp.groupId,
                  reportId: htmlTemp.reportId
                }
                dispatch(getEmbedToken(params));
              }
            }

          }
        })
          .catch((err) => {
            toast.error(err.response.data.message, { position: toast.POSITION.RIGHT_TOP });
          });
      }
    }
  }

  const SelectedTags = (tags) => {
    setTagsKeyword(tags)
  };

  const reportPageListMethod = (e) => {
    let val = e.currentTarget.value;
    console.log('reportPageListMethod', val)
    if (val == 0) {
      setFetchPages(0);
      setIsReportPages(true);
      setIsGroupedReport(false);
    }
    else if (val == 1) {
      setFetchPages(1)
      setIsReportPages(false);
      setIsGroupedReport(true);
    }
    else if (val == 2) {
      setFetchPages(2)
      setIsReportPages(false)
      setIsGroupedReport(false);
    }
  };

  const embedReportPage = () => {
      return (
        <div className="col-12  col-xl-12 form-group tile-new">
          <label> Embed Report Type </label>
          <br></br>
          <input className='px-radio' type={'radio'} value={0} name="isReportPages" checked={fetchPages === 0} onChange={(e) => reportPageListMethod(e, 0)} /> Embed Report Page
          <br></br>
          <input className='px-radio' type={'radio'} value={1} name="groupedReport" checked={fetchPages === 1} onChange={(e) => reportPageListMethod(e, 1)} /> Grouped Report
          <br></br>
          <input className='px-radio' type={'radio'} value={2} name="pbixReport" checked={fetchPages === 2} onChange={(e) => reportPageListMethod(e, 2)} /> PBIx Report
        </div>
      )
  };

  const renderReportPages = () => {
    let activeReport = playBookData.viewBuilderJSON;
    let { grouped } = activeReport;
    if (grouped && isReportPages && !customizedReport) {
      return (
        <div className="col-12  col-xl-12 form-group tile-new">
          <label className="">
            Report Pages
          </label>
          <select value={reportPageId} className="form-control" onChange={(e) => setReportPageId(e.target.value)}>
            <option disabled="">Select</option>
            {reportSectionPages && reportSectionPages.length > 0 ?

              reportSectionPages.map((el) =>
                <option value={el.name}>{el.displayName}</option>
              )
              :
              <option value={""}>{'Please wait till report loads, we are fecthing pages from Report'}</option>
            }
          </select>
        </div>
      )
    }
  };

  const groupedReportLandingPage = (groupedReportPages) =>{
    setGroupedReportPages(groupedReportPages);
  }

  const renderLandingPages = () => {
    if(isGroupedReport){
      return (
        <div className="col-12  col-xl-12 form-group tile-new">
          <label className="tile-new">
            Reports Landing Page
          </label>
          <TagsInput className="form-control tile-new" placeholder="Enter the report name for the landing page." selectedTags={(e) => groupedReportLandingPage(e)} />
        </div>
      )
    }
  };

  const renderDashboardStatus = () => {
    let { subReportData } = groupedSubReport;
    if (Object.keys(subReportData).length > 0 && subReportData.subReportId !== '' && !newReport) {
      return (
        <div className="col-12  col-xl-12 form-group tile-new">
          <label className="">
            Status
          </label>
          <select value={viewStatus} className="form-control" onChange={(e) => setviewStatus(e.target.value)}>
            <option disabled="">Select</option>
            <option value="Active">Active</option>
            <option value="Inactive">Inactive</option>
            <option value="Draft">Draft</option>
          </select>
        </div>
      )
    }
  };


  const renderReportDetails = () => {
    return (
      <div>
        <div className="col-12  col-xl-12 form-group tile-new">
          <label>
            Report Name
          </label>
          <input value={displayName} type="text" name={'displayName'} className="form-control tile-new" placeholder="Label for dashboard" onChange={(e) => onChangeGroupedReport(e)} />
        </div>
        <div className="col-12  col-xl-12 form-group tile-new">
          <label>
            Report Description
          </label>
          <input value={viewDescription} type="text" name={'viewDescription'} className="form-control tile-new" placeholder="Enter short description for the dashboard." onChange={(e) => onChangeGroupedReport(e)} />
        </div>
        <div className="col-12  col-xl-12 form-group tile-new">
          <label className="tile-new">
            Report Tags/Keyword
          </label>
          <TagsInput className="form-control tile-new" placeholder="Enter the tags/keyword for the reports." selectedTags={(e) => SelectedTags(e)} />
        </div>
        <div className="col-12  col-xl-12 form-group tile-new">
          <label> Please Enter Report Link <small>(Ensure the link has http(s) included)</small></label>
          <input
            value={linkForDocumentation}
            type="text"
            className="form-control"
            name={'linkForDocumentation'}
            placeholder="Enter the Report"
            onChange={(e) => onChangeGroupedReport(e)}
          />
        </div>
        {embedReportPage()}
        {renderReportPages()}
        {renderLandingPages()}
        {renderDashboardStatus()}
        {
          InsertNewSubReport.loading ?
            <div className="waiting_Loader">
              <div className="prettier">Please wait...</div>
              <BarLoader
                size={150}
                color={"#123abc"}
                height={4}
                width={100}
                loading={true}
              />
            </div>
            :
            <div className="col-12  col-xl-12 form-group  arrangments">
              <button type="button" className="btn btn-apply tile-new" onClick={(e) => { e.preventDefault(); saveNewGroupedReport() }}>Save</button>
            </div>
        }
      </div>
    )
  }

  const addGroupedReport = () => {
    return (
      <Modal
        open={showSettingsWindow}
        bodyElement={
          renderReportDetails()
        }
        hideSettingsWindow={() => setShowSettingWindow(false)}
        title={'Add Sub Report'}
        size={"md"}
      />
    )
  };

  const addNewGroupedReport = () => {
    setNewReport(true);
    setShowSettingWindow(true);
    setDisplayName('');
    setViewDescription('');
    setLinkForDocumentation('');
    setCustomizedReport(false);
  }

  const renderPageNavigation = () => {
    let activeReport = playBookData.viewBuilderJSON;
    let { data } = playbookContentPages;
    return (
      <List style={{ width: "100%", paddingTop: '0px' }}>
        <ListItem
          button
          key={activeReport.viewName}
          className={classes.activeMenuList}
        >
          <ListItemText primary={ activeReport.viewName} />
          {Array.isArray(data) && data.length > 0 ? renderSubMenu() : null}
        </ListItem>
        {activeReport.grouped ?
          <button className="btn addNewBoard alignment mt-5" onClick={(e) => addNewGroupedReport()}>
            <PlusCircleFill />Add Sub Report
          </button>
          : null
        }
      </List>
    )
  }

  const renderPowerBIDashboard = () => {
    let activeReport = playBookData.viewBuilderJSON;
    let { data, loading } = playbookContentPages;
    let { msalEnabled, grouped } = activeReport;
    if (!!activeReport && Object.keys(activeReport).length > 0) {
      if (!msalEnabled && !!powerbiToken.loading && powerbiToken.embedToken === '') {
        console.log('renderChildrenOfId', 333, powerbiToken, playbookContentPages, !playbookContentPages.loading);
        if (grouped && Array.isArray(data) && data.length === 0 && !loading) {
          return (
            <div className={classes.serverDown}>
              <div className="noReports" style={{ margin: 'auto' }}></div>
              <div className="tile-new">No Sub Report Available, Please Embed it</div>
            </div>
          )
        }
        else {
          return (
            <div className={classes.position}>
              <CircularProgress color="primary" />
            </div>
          )
        }
      }
      else if (!msalEnabled && !powerbiToken.loading && !powerbiToken.responseDefault && !!powerbiToken.error && powerbiToken.embedToken === '') {
        console.log('renderChildrenOfId', 444, powerbiToken);
        return (
          <div className={classes.serverDown}>
            <div className="noReports" style={{ margin: 'auto' }}></div>
            <div className="tile-new" style={{ display: "flex", justifyContent: "center" }}>{embedError}</div>
            <div className="tile-new" >Or Please Try with RLS Based Emedding</div>
          </div>
        )
      }
      else if (grouped && Object.keys(htmlTemp).length === 0) {
        return (
          <div className={classes.serverDown}>
            <div className="noReports" style={{ margin: 'auto' }}></div>
            <div className="tile-new" style={{ display: "flex", justifyContent: "center" }}>{embedError}</div>
            <div className="tile-new" >  Please Embed Sub Report</div>
          </div>
        )
      }
      else if (htmlTemp && htmlTemp.reportId !== "" && htmlTemp.groupId !== "") {
        if (!msalEnabled) {
          if (htmlTemp && htmlTemp['reportId'] && reportIDs['reportId'] && htmlTemp['reportId'] === reportIDs['reportId']) {
            let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${htmlTemp.reportId}&groupId=${htmlTemp.groupId}`;
            console.log('renderChildrenOfId', 888, activeReport, powerbiToken, htmlTemp, embedUrl);
            return (
              <Report
                tokenType={'Embed'}
                embedType="report"
                accessToken={powerbiToken.embedToken}
                embedUrl={embedUrl}
                embedId={htmlTemp.reportId}
                reportMode="View"
                groupId={htmlTemp.groupId}
                extraSettings={extraSettings}
                permissions="All"
                style={style}
                onRender={(report) => {
                  UpdateReportPages(report);
                  setCurrentReport(report);
                }}
                onError={(data) => console.log('onError', data)}
                onPageChange={(data) => handlePageChange(data, htmlTemp.reportId, htmlTemp.groupId)}
              />
            )
          }
        }
        else if (msalEnabled && htmlTemp.msalToken && htmlTemp.msalToken !== '') {
          console.log('renderChildrenOfId', 777, activeReport, htmlTemp);
          if (htmlTemp && htmlTemp['reportId'] && reportIDs['reportId'] && htmlTemp['reportId'] === reportIDs['reportId']) {
            let embedUrl = `https://app.powerbi.com/reportEmbed?reportId=${htmlTemp.reportId}&groupId=${htmlTemp.groupId}`;
            console.log('renderChildrenOfId', 999, activeReport, embedUrl, extraSettings, htmlTemp, reportIDs);
            return (
              <Report
                tokenType="access"
                embedType="report"
                accessToken={htmlTemp.msalToken}
                embedUrl={embedUrl}
                embedId={htmlTemp.reportId}
                reportMode="View"
                groupId={htmlTemp.groupId}
                extraSettings={extraSettings}
                permissions="All"
                style={style}
                onRender={(report) => {
                  UpdateReportPages(report);
                }}
                onError={(data) => {
                  console.log('onError', data);
                  if (data['message'] === "TokenExpired") {
                    handleTokenExpireError()
                  }
                }
                }
                onPageChange={(data) => handlePageChange(data, htmlTemp.reportId, htmlTemp.groupId)}
              />
            )
          }
        }
      }
    }
  };

  return (
    <div className={classes.root}>
      <Paper className={classes.previewPaper}>
        <Grid container item xs={3} className={classes.flexContainer}>
          <ToastContainer />
          {renderPageNavigation()}
          {addGroupedReport()}
        </Grid>
        <Grid container item xs={9} className={classes.flexContainer}>
          {!isMsalTokenExpired ?
            renderPowerBIDashboard()
            :
            <div style={{
              width: '300px',
              margin: '9% auto auto',
              height: '200px',
              background: 'white',
              borderRadius: '10px'
            }}>
              <div style={{ position: "relative", top: "37%", fontSize: '14px' }}>
                Loading...
                <br />
                Please wait, loading Required Resources.
              </div>
            </div>}
        </Grid>
      </Paper>
    </div>
  );
}

export default PreviewDashboard;