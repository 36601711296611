import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useStyles } from "./style.js";
import { useHistory } from "react-router-dom";
import cloneDeep from 'lodash/cloneDeep';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import {
    updateActiveBusinessUnit,
    deleteChildModule,
    deActivateParentModule,
    toastTriggered,
    getAllRegionBuIds,
    updateActivePage,
    updatesidebarMenuList,
    updateReportContent,
    parentBusinessMappedIds,
    getChildParentMappedData,
    getRegions
} from "../../redux/actions/index";
import {
    createTheme,
    MuiThemeProvider,
} from "@material-ui/core/styles";
import arrowRight from '../../assets/icons/arrowRight.svg';
import analytics from '../../assets/icons/Self Service Analytics.svg';
import foundationAnalytics from '../../assets/icons/foundationAnalytics.svg';
import resources from '../../assets/icons/resources.svg';
import strategicPricing from '../../assets/icons/Strategic Pricing.svg';
import PPA from '../../assets/icons/Price Pack Architecture.svg';
import mixm from '../../assets/icons/Mix Management.svg';
import tprom from '../../assets/icons/Trade Promotions.svg';
import cpol from '../../assets/icons/Commercial Policy.svg';
import additional from '../../assets/icons/Customized Reports.svg';
import CT from '../../assets/icons/control tower.svg';
import dartboards from '../../assets/icons/dartboards.svg';
import utils from '../../utils/index.js';
import { RightArrowIcon, EditIcon, ServerIcon, TrashIcon, PlusCircleFill, LeftArrowIcon } from '../../assets/icons/Icons';
import ContentDialog from './dialog.js';
import ParentModule from './parentModule.js';
import ChildModule from './childModule.js';
import ModuleOrder from './moduleOrder.js';
import toastProperties from "../../assets/data/toastProperty";
import '../businessUnit/style.css';

const BusinessUnit = (props) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    let history = useHistory();
    const { subRegion } = props;
    const { activeBusinessUnit, activeRegion } = useSelector((state) => state.activeDynamicData);
    const [selectedModule, setSelectedModule] = useState({});
    const parentMappedIds = useSelector((state) => state.parentBuMapped);
    const RegionMappingData = useSelector((state) => state.getRegionMappingData);

    const buCardRef = useRef(null);

    let imageList = {
        analytics,
        foundationAnalytics,
        resources,
        arrowRight,
        strategicPricing,
        PPA,
        mixm,
        tprom,
        cpol,
        additional,
        CT,
        dartboards
    };

    const theme = createTheme({
        overrides: {
            MuiTooltip: {
                tooltip: {
                    fontSize: "1em",
                    color: "#fff",
                    fontFamily: 'Gilroy-Medium',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: '20px'/* 142.857% */,
                    marginBottom: '0px',
                    padding: '5px 10px',
                    zIndex: 1,
                }
            }
        }
    });

    useEffect(() => {
        let updateBusinessUnit = cloneDeep(activeBusinessUnit);
        if (Array.isArray(updateBusinessUnit) && updateBusinessUnit.length > 0) {
            updateBusinessUnit.map((bu, index) => {
                let addNew = [{
                    childId: `${bu.regionBuId + index}`,
                    parentId: `${bu.parentId}`,
                    title: "Add New Child Module",
                    path: "/addNewChild",
                    key: "addNewChildModule",
                    parentModule: `${bu.parentModule}`,
                    description: "Adding New Child Module",
                    sideBarKey: "",
                    background: "#E66000",
                    pageName: `${bu.parentModule}`,
                    buttonElement: true,
                    viewStatus: 'Active',
                    subReport: true
                }];
                if (Array.isArray(bu.childModule)) {
                    bu.childModule.forEach((child) => {
                        if (!child.hasOwnProperty('buttonElement')) {
                            child.buttonElement = false;
                        }
                    });
                    const finalArray = bu.childModule.concat(addNew);
                    bu.childModule = utils.uniqueArrayData(finalArray, 'title');
                }
                activeBusinessUnit[index].childModule = bu.childModule;
                return activeBusinessUnit;
            });
            dispatch(updateActiveBusinessUnit(activeBusinessUnit));
        }
    }, [activeBusinessUnit, dispatch]);


    const collpaseChildModule = (index) => {
        let activeParentModule = Array.isArray(activeBusinessUnit) && activeBusinessUnit.length > 0 && activeBusinessUnit[index]
        activeParentModule.collapse = !activeParentModule.collapse;
        dispatch(updateActiveBusinessUnit(activeBusinessUnit));
    };

    const updateParentModule = (parentModuleData, order) => {
        let moduleOperation = {};
        moduleOperation.openDialog = true;
        moduleOperation.createContent = false;
        moduleOperation.parentDialog = true;
        moduleOperation.childDialog = false;
        moduleOperation.enableParentUpdateSetting = true;
        moduleOperation.enableChildUpdateSetting = false;
        moduleOperation.activateParent = true;
        moduleOperation.title = 'Update Parent Module';
        moduleOperation.parentModuleData = parentModuleData;
        moduleOperation.order = order;
        setSelectedModule(moduleOperation);
        let regionBuId = utils.uniqueRegionBuId(RegionMappingData)
        let params = { region: activeRegion, parentModule: parentModuleData.parentModule, regionBuId: regionBuId }
        dispatch(parentBusinessMappedIds(params)).then((res) => {
            let parentChildMappingData = res.data.data;
            let parentId = [];
            parentChildMappingData.forEach((el) =>
                parentId.push(el.parentId)
            )
            let params = { region: activeRegion, parentIds: parentId, parentModule: parentChildMappingData[0].parentModule }
            dispatch(getChildParentMappedData(params))
        })
    }

    const updateChildModule = (childModuleData, parentData, order) => {
        let moduleOperation = {};
        moduleOperation.openDialog = true;
        moduleOperation.createContent = false;
        moduleOperation.parentDialog = false;
        moduleOperation.childDialog = true;
        moduleOperation.activateChild = true;
        moduleOperation.title = 'Update Child Module';
        moduleOperation.parentModuleData = parentData;
        moduleOperation.childModuleData = childModuleData;
        moduleOperation.order = order;
        moduleOperation.enableParentUpdateSetting = false;
        moduleOperation.enableChildUpdateSetting = true;
        setSelectedModule(moduleOperation);
        let regionBuId = utils.uniqueRegionBuId(RegionMappingData)
        let params = { region: activeRegion, parentModule: parentData.parentModule, title: childModuleData.title, regionBuId: regionBuId }
        dispatch(parentBusinessMappedIds(params)).then((res) => {
            let parentChildMappingData = res.data.data;
            let parentId = [];
            parentChildMappingData.forEach((el) =>
                parentId.push(el.parentId)
            )
            let params = { region: activeRegion, parentIds: parentId, parentModule: parentChildMappingData[0].parentModule, title: childModuleData.title }
            dispatch(getChildParentMappedData(params))
        })
    };


    const addParentModule = () => {
        let moduleOperation = {};
        let params = {};
        moduleOperation.openDialog = true;
        moduleOperation.createContent = true;
        moduleOperation.parentDialog = true;
        moduleOperation.childDialog = false;
        moduleOperation.parentModuleData = {};
        moduleOperation.title = 'Add Parent Module';
        moduleOperation.enableParentUpdateSetting = false;
        moduleOperation.enableChildUpdateSetting = false;
        setSelectedModule(moduleOperation);
        params.region = activeRegion;
        if (activeRegion === 'APAC') {
            params.subRegion = 'KOREA'
        }
        else if (activeRegion === 'LAO') {
            params.subRegion = 'SLAO'
        }
        dispatch(getAllRegionBuIds(params))
    };

    const addChildModule = (parentModuleData, order, units) => {
        let moduleOperation = {};
        let params = {};
        moduleOperation.openDialog = true;
        moduleOperation.createContent = true;
        moduleOperation.parentDialog = false;
        moduleOperation.childDialog = true;
        moduleOperation.parentModuleData = units;
        // moduleOperation.childModuleData = data;
        moduleOperation.title = 'Add Child Module';
        moduleOperation.order = order;
        moduleOperation.enableParentUpdateSetting = false;
        moduleOperation.enableChildUpdateSetting = false;
        setSelectedModule(moduleOperation);
        params.region = activeRegion;
        if (activeRegion === 'APAC') {
            params.subRegion = 'KOREA'
        }
        else if (activeRegion === 'LAO') {
            params.subRegion = 'SLAO'
        }
        dispatch(getAllRegionBuIds(params)).then((res) => {
            let data = res.data;
            let regionBuId = utils.uniqueRegionBuId(data)
            let params = { region: activeRegion, parentModule: parentModuleData.parentModule, regionBuId: regionBuId }
            dispatch(parentBusinessMappedIds(params))
        })
    };

    const enableUpdateParentModule = () => {
        let moduleOperation = {};
        let params = {};
        moduleOperation.openDialog = false;
        moduleOperation.parentDialog = false;
        moduleOperation.activateParent = true;
        moduleOperation.enableParentUpdateSetting = true;
        moduleOperation.enableChildUpdateSetting = false;
        setSelectedModule(moduleOperation);
        params.region = activeRegion;
        if (activeRegion === 'APAC') {
            params.subRegion = 'KOREA'
        }
        else if (activeRegion === 'LAO') {
            params.subRegion = 'SLAO'
        }
        dispatch(getAllRegionBuIds(params))
    };

    const enableUpdateChildModule = () => {
        let moduleOperation = {};
        let params = {};
        moduleOperation.openDialog = false;
        moduleOperation.childDialog = false;
        moduleOperation.activateChild = true;
        moduleOperation.enableParentUpdateSetting = false;
        moduleOperation.enableChildUpdateSetting = true;
        setSelectedModule(moduleOperation);
        params.region = activeRegion;
        if (activeRegion === 'APAC') {
            params.subRegion = 'KOREA'
        }
        else if (activeRegion === 'LAO') {
            params.subRegion = 'SLAO'
        }
        dispatch(getAllRegionBuIds(params))
    };

    const updateParentModuleOrder = (data) => {
        let moduleOperation = {};
        moduleOperation.openDialog = true;
        moduleOperation.createContent = false;
        moduleOperation.parentDialog = false;
        moduleOperation.parentOrder = true;
        moduleOperation.childOrder = false;
        moduleOperation.activateParent = true;
        moduleOperation.childDialog = false;
        moduleOperation.parentModuleData = data;
        moduleOperation.title = 'Update Parent Module Order';
        moduleOperation.enableParentUpdateSetting = false;
        moduleOperation.enableChildUpdateSetting = false;
        setSelectedModule(moduleOperation);
    };

    const updateChildModuleOrder = (data) => {
        let moduleOperation = {};
        moduleOperation.openDialog = true;
        moduleOperation.createContent = false;
        moduleOperation.parentDialog = false;
        moduleOperation.childDialog = false;
        moduleOperation.childOrder = true;
        moduleOperation.activateChild = true;
        moduleOperation.parentOrder = false;
        moduleOperation.childModuleData = data;
        moduleOperation.title = 'Update Child Module Order';
        moduleOperation.enableParentUpdateSetting = false;
        moduleOperation.enableChildUpdateSetting = false;
        setSelectedModule(moduleOperation);
    };

    const trackEvents = (moduleData, type) => {
        utils.userTrackingInfo({
            region: activeRegion,
            actionType: 'Landing Page Admin Activity',
            pageName: 'Dashboard',
            reportName: moduleData.parentModule,
            pageDetails: type,
            attributeName: moduleData.pageName
        });
    }

    const deleteParentModuleMapping = (moduleData) => {
        const parentIds = [];
        const { parentBuMapping } = parentMappedIds;
        let regionBuId = utils.uniqueRegionBuId(RegionMappingData)
        let Mappingparams = { region: activeRegion, parentModule: moduleData.parentModule, title: moduleData.title, regionBuId: regionBuId }
        let text = `Are You Sure You Want to Delete ${moduleData.title} Module ? This Action Can't be UNDONE`;
        if (window.confirm(text)) {
            if (Array.isArray(parentBuMapping) && parentBuMapping.length === 0) {
                dispatch(parentBusinessMappedIds(Mappingparams)).then((res) => {
                    let parentIdData = res.data.data;
                    Array.isArray(parentIdData) && parentIdData.length > 0 && parentIdData.forEach((el) => {
                        parentIds.push(el.parentId)
                    })
                    let params = { parentModule: moduleData.parentModule, title: moduleData.title, parentId: parentIds, };
                    dispatch(deActivateParentModule(params)).then((res) => {
                        trackEvents(moduleData, "Parent Module deactivated");
                        toastProperties.success["description"] = res.data.message;
                        dispatch(toastTriggered([toastProperties.success]));
                        dispatch(getRegions());
                        dispatch({ type: "PARENT_BUSINESS_MAPPED_UNIT_REQUEST" });
                    }).catch((err) => {
                        toastProperties.error["description"] = err.message;
                        dispatch(toastTriggered([toastProperties.error]));
                    });
                })
            }
            else if (Array.isArray(parentBuMapping) && parentBuMapping.length > 0) {
                parentBuMapping.forEach((el) => {
                    parentIds.push(el.parentId)
                });
                let params = { parentModule: moduleData.parentModule, title: moduleData.title, parentId: parentIds, };
                dispatch(deActivateParentModule(params)).then((res) => {
                    trackEvents(moduleData, "Parent Module deactivated");
                    toastProperties.success["description"] = res.data.message;
                    dispatch(toastTriggered([toastProperties.success]));
                    dispatch(getRegions());
                    dispatch({ type: "PARENT_BUSINESS_MAPPED_UNIT_REQUEST" });
                }).catch((err) => {
                    toastProperties.error["description"] = err.message;
                    dispatch(toastTriggered([toastProperties.error]));
                });
            }
        }
    };

    const deleteChildModuleMapping = (moduleData, parentModule) => {
        const childIds = [];
        const parentId = [];
        let regionBuId = utils.uniqueRegionBuId(RegionMappingData)
        let params = { region: activeRegion, parentModule: parentModule.parentModule, title: moduleData.title, regionBuId: regionBuId }
        console.log('deleteChildModuleMapping',  moduleData, params);
        let text = `Are You Sure You Want to Delete ${moduleData.title} Child Module Parmanently? This Action Can't be UNDONE`;
        if (window.confirm(text)) {
            dispatch(parentBusinessMappedIds(params)).then((res) => {
                let parentChildMappingData = res.data.data;
                parentChildMappingData.forEach((el) =>
                    parentId.push(el.parentId)
                )
                dispatch(getChildParentMappedData({ parentIds: parentId, title: moduleData.title, parentModule: parentChildMappingData[0].parentModule })).then((res) => {
                    let childIdData = res.data.data;
                    Array.isArray(childIdData) && childIdData.length > 0 && childIdData.forEach((el) => {
                        childIds.push(el.childId)
                    });
                    let params = { title: moduleData.title, childId: childIds };
                     dispatch(deleteChildModule(params)).then((res) => {
                        trackEvents(moduleData, "Child Module Parmanent Deleted");
                        toastProperties.success["description"] = res.data.message;
                        dispatch(toastTriggered([toastProperties.success]));
                        dispatch(getRegions());
                        dispatch({ type: "CHILD_PARENT_MAPPPED_DATA_REQUEST" });

                    }).catch((err) => {
                        toastProperties.error["description"] = err.message;
                        dispatch(toastTriggered([toastProperties.error]));
                    });
                })
            })

        }
    };

    const NavigateTo = (pathName, activeModule, units) => {
        const params = {
            pageType: activeModule.key,
            pageName: activeModule.parentModule,
            sideBar: activeModule.sideBarKey,
            businessUnitData: units,
            region: activeRegion,
            subRegion: subRegion,
            background: activeModule.background,
        };
        if (activeModule.embedLinkType && activeModule.path !== '') {
            window.open(
                activeModule.path,
                "_blank"
            );
        }
        else {
            history.push({
                pathname: pathName,
                state: params
            });
        }
        if (activeModule.key === "Control Tower") {
            sessionStorage.setItem('region', "Global");
        }
        dispatch(updateActivePage(activeModule))
        sessionStorage.setItem('ActivePage', JSON.stringify(activeModule));
        dispatch(updatesidebarMenuList([]));
        dispatch(updateReportContent({}));
        dispatch({ type: "GET_PLAYBOOKS_CONTENT_PAGES_REQUEST" });
        utils.userTrackingInfo({
            region: activeRegion,
            actionType: 'Click',
            pageName: activeModule.pageName,
            pageDetails: `Redirected from Home to ${activeModule.pageName}`,
        });
    };


    const scrollRight = () => {
        buCardRef.current.scrollBy({
            left: 8000, //scrollDistance
            behavior: 'smooth' // Optional: adds smooth scrolling animation
        });
    };

    const scrollLeft = () => {
        buCardRef.current.scrollLeft -= 20000
    }

    const renderBusinessUnit = () => {
        const { enableParentUpdateSetting, enableChildUpdateSetting } = selectedModule;
        let data = activeBusinessUnit.filter((el) => el.viewStatus !== 'Inactive');
        data.sort((function (a, b) { return a.parentOrder - b.parentOrder }));
        if (Array.isArray(data) && data.length > 0) {
            return (
                data.map((units, index) =>
                (
                    <Grid container className={classes.parentModulePosition} key={index}  item xs={2}>
                        <Grid style={{ background: units.background }} className={classes.parentModule}>
                            <Grid container  item xs={12} >
                                {
                                    enableParentUpdateSetting && !enableChildUpdateSetting ?
                                        <Grid container item xs={12} className={classes.rightAlign} >
                                            <Grid className={classes.EditParent} onClick={() => updateParentModule(units, data.length)}>
                                                <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Update Parent Module">  <EditIcon /> </span>
                                            </Grid>
                                            <Grid className={classes.EditParent} onClick={() => updateParentModuleOrder(data)}>
                                                <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Update Parent Module Order"> <ServerIcon /></span>
                                            </Grid>
                                            <Grid className={classes.EditParent} onClick={() => deleteParentModuleMapping(units)}>
                                                <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Delete Parent Module">  <TrashIcon /></span>
                                            </Grid>
                                        </Grid>
                                        : null
                                }
                            </Grid>
                            <Grid container  item xs={12}>
                                <Grid item xs={12} className={classes.logoBox} onClick={() => collpaseChildModule(index)}>
                                    <img className={classes.parentImg} alt="complex" src={imageList[units.image]} />
                                </Grid>
                                <Grid  item xs={12} className={classes.parentContent}>
                                    <Typography variant="h5" className={classes.ParentTitle}>
                                        {units.title}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid container  item className={classes.childModulePosition} key={index} xs={12} >
                            {!units.collapse ?
                                units.childModule.length > 0 &&
                                units.childModule.sort((function (a, b) { return a.childOrder - b.childOrder })) &&
                                units.childModule.filter((el) => el.subReport).map((unit, childIndex) =>
                                (
                                    !unit.buttonElement ?
                                        <Grid item key={childIndex} xs={12} className={classes.childModule} >
                                            <Grid className={classes.flexRow}>
                                                <Grid className={classes.contentBox}>
                                                    <Typography variant="h5" className={classes.Title}>
                                                        {unit.title}
                                                    </Typography>
                                                    {
                                                        unit.description && unit.description.length > 25 ?
                                                            <MuiThemeProvider theme={theme}>
                                                                <Tooltip title={unit.description}>
                                                                    <Typography variant="subtitle2" className={classes.subtitle}>
                                                                        {unit.description}
                                                                    </Typography>
                                                                </Tooltip>
                                                            </MuiThemeProvider>
                                                            :
                                                            <Typography variant="subtitle2" className={classes.subtitle}>
                                                                {unit.description}
                                                            </Typography>
                                                    }
                                                    {
                                                        unit.link !== '' ?
                                                            <Grid className={classes.selectUnit} onClick={() => NavigateTo(unit.path, unit, units)}>
                                                                <Typography variant="button" className={classes.select}>
                                                                    View More
                                                                </Typography>
                                                                <IconButton edge="start" className={classes.forwordIcon} fontSize="small" color="inherit" aria-label="menu">
                                                                    <img className={classes.img} alt="complex" src={imageList['arrowRight']} />
                                                                </IconButton>
                                                            </Grid>
                                                            :
                                                            <Grid className={classes.selectUnit} >
                                                                <Typography variant="button" className={classes.select}>
                                                                    Coming Soon
                                                                </Typography>
                                                            </Grid>
                                                    }
                                                </Grid>
                                                {
                                                    enableChildUpdateSetting && !enableParentUpdateSetting ?
                                                        <Grid>
                                                            <Grid className={classes.editIcon} onClick={() => updateChildModule(unit, units, units.childModule.length)}>
                                                                <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Update Child Module"> <EditIcon /> </span>
                                                            </Grid>
                                                            <Grid className={classes.editIcon} onClick={() => updateChildModuleOrder(units.childModule)}>
                                                                <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Update Child Module Order">  <ServerIcon /></span>
                                                            </Grid>
                                                            <Grid className={classes.editIcon} onClick={() => deleteChildModuleMapping(unit, units)}>
                                                                <span className="d-flex" data-toggle="tooltip" data-placement="top" title="Delete Child Module">  <TrashIcon /></span>
                                                            </Grid>

                                                        </Grid>
                                                        : null
                                                }
                                            </Grid>
                                        </Grid>
                                        :
                                        <Grid key={childIndex} item xs={12} className="addNewBoard addModule mt-3" onClick={() => addChildModule(unit, units.childModule.length, units)}>
                                            <PlusCircleFill /> Add New Child Module
                                        </Grid>
                                )
                                )
                                :
                                (
                                    <Grid container key={index} xs={12} className={classes.childModule}>
                                        <Grid className={classes.flexRow}>
                                            <Grid className={classes.contentBox}>
                                                <Typography variant="subtitle2" className={classes.subtitle}>
                                                    {units.collapseText}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                )

                            }
                        </Grid>
                    </Grid>
                )
                )
            )
        }
    };

    const closeDialog = () => {
        let module = cloneDeep(selectedModule);
        module.openDialog = false;
        setSelectedModule(module);
    }

    const renderModuleContent = () => {
        const { parentDialog, childDialog, childOrder, parentOrder } = selectedModule;
        if (parentDialog) {
            return (
                <ParentModule moduleData={selectedModule} closeDailog={() => closeDialog()} />
            )
        }
        else if (childDialog) {
            return (
                <ChildModule moduleData={selectedModule} closeDailog={() => closeDialog()} />
            )
        }
        else if (parentOrder || childOrder) {
            return (
                <ModuleOrder moduleData={selectedModule} closeDailog={() => closeDialog()} />
            )
        }
    }

    const renderContentDialog = () => {
        const { createContent, title, openDialog, parentDialog } = selectedModule;
        if (Object.keys(selectedModule).length > 0) {
            return (
                <ContentDialog
                    createContent={createContent}
                    contentTitle={title}
                    openDialog={openDialog}
                    parentDialog={parentDialog}
                    closeDialog={closeDialog}
                    moduleContent={renderModuleContent()}
                />
            )
        }
    };

    const renderModule = () => {
        const { parentDialog, activateParent, activateChild } = selectedModule;
        return (
            <Grid container item xs={12} className={classes.flexRow} >
                <div className={`addModule  ${parentDialog && !activateParent ? 'activate' : null}`} onClick={() => addParentModule()}>
                    <PlusCircleFill /> Add New Parent Module
                </div>
                <div className={`addModule ${activateParent ? 'activate' : null}`} onClick={() => enableUpdateParentModule()}>
                    <EditIcon /> Update Parent Module
                </div>
                <div className={`addModule ${activateChild ? 'activate' : null}`} onClick={() => enableUpdateChildModule()}>
                    <EditIcon /> Update Child Module
                </div>
                <div className={`landingState`} onClick={() => Preview()}>
                    Preview
                </div>
            </Grid>
        )
    };

    const Preview = () => {
        history.push({
            pathname: '/landingPage/preview',
        });
    };



    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <Grid container item xs={12} className={classes.flexContainer}>
                    <Grid container item xs={12}>
                        <Grid item xs={2} className="appTitle">
                            RGM Command Center
                        </Grid>
                        <Grid  item xs={10}>
                            {
                                renderModule()
                            }
                        </Grid>
                    </Grid>
                    <Grid container item xs={12}>
                        <Grid container item className={classes.scrollRight} xs={1}>
                            <div className={classes.scrollLeftPos} onClick={() => scrollLeft()}><LeftArrowIcon /></div>
                        </Grid>
                        <Grid container item className={classes.modulePosition} xs={10} ref={buCardRef}>
                            {
                                renderBusinessUnit()
                            }
                            {
                                renderContentDialog()
                            }

                        </Grid>
                        <Grid container item className={classes.scrollRight} xs={1}>
                            <div className={classes.scrollRightPos} onClick={() => scrollRight()}><RightArrowIcon /></div>
                        </Grid>
                    </Grid>
                    <Grid  item xs={12} className={classes.righReserved}>
                        © 2001-2020 KCWW. All Rights Reserved.
                    </Grid>
                </Grid>
            </Paper>
        </div>
    );
}

export default BusinessUnit;