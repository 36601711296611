import React, { useEffect } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { UserAgentApplication } from "msal";
import { mslLogin, handleSilentLoginRedirect, loginRequest, msalConfig } from "./loginrequest";
import './style.css';
import { useHistory } from 'react-router-dom';

const MSALCallback = (props) => {
    console.log("i am in mount", 'MSALCallback')
    let history = useHistory()
    const { authState } = useOktaAuth();
    const msalInstance = new UserAgentApplication(msalConfig);

    useEffect(() => {
        if (!!authState && !authState.isAuthenticated) {
            // window.location.replace('/');
            // console.log("i am in mount", "USER MMM Reached HERE.....")
         }
        else if (!!authState && !!authState.isAuthenticated) {
            // console.log("i am in mount", "multiple api call MSALCAllback if part", msalInstance.getAccount())
            if (msalInstance.getAccount()) {
                // console.log("i am in mount", "msalInstance, MSALCAllback getAccount")
                handleSilentLoginRedirect(msalInstance, loginRequest, history)
            }
            else {
                // console.log("i am in mount","multiple api call MSALCAllback else part", msalInstance,history)
                mslLogin(msalInstance, history);
            }
       }
    }, [authState, history, msalInstance, props, props.history]);

    return (
        <div className="msalLoading">
            <div className='msalText'>
                Loading...
                <br />
                Please wait, loading Required Resources.
            </div>
        </div>
    );
};

export default MSALCallback;